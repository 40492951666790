import { default as _91slug_93RyVVoKkmcHMeta } from "/vercel/path0/pages/[slug].vue?macro=true";
import { default as accountLUTTRGMhg3Meta } from "/vercel/path0/pages/account.vue?macro=true";
import { default as _91key_93kSGHgAtKuLMeta } from "/vercel/path0/pages/accounts/confirm-email/[key].vue?macro=true";
import { default as affiliatess6GrbePN4RMeta } from "/vercel/path0/pages/affiliates.vue?macro=true";
import { default as _91slug_93jdlVCCdpkFMeta } from "/vercel/path0/pages/appsumo/[slug].vue?macro=true";
import { default as appsumogaQQbdHfPWMeta } from "/vercel/path0/pages/appsumo.vue?macro=true";
import { default as articlesSToQQf8MXRMeta } from "/vercel/path0/pages/articles.vue?macro=true";
import { default as mineQ3lQc6ywlTMeta } from "/vercel/path0/pages/assets/mine.vue?macro=true";
import { default as _91key_93e19YskBsh7Meta } from "/vercel/path0/pages/audio/[key].vue?macro=true";
import { default as clipsmxvoTF3GEKMeta } from "/vercel/path0/pages/audio/clips.vue?macro=true";
import { default as uploadsIqLnQdp5SzMeta } from "/vercel/path0/pages/audio/uploads.vue?macro=true";
import { default as _91slug_93RLVJqb0vXlMeta } from "/vercel/path0/pages/auth/[slug].vue?macro=true";
import { default as _91slug_93Po03rnzIFtMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as checkoutlZQLvi17ODMeta } from "/vercel/path0/pages/checkout.vue?macro=true";
import { default as confirm_45_91key_93NZ12eh0qGPMeta } from "/vercel/path0/pages/clip/confirm-[key].vue?macro=true";
import { default as design_45_91key_93jzJ9NWXMkQMeta } from "/vercel/path0/pages/clip/design-[key].vue?macro=true";
import { default as final_45_91key_93vx9DVI2JTDMeta } from "/vercel/path0/pages/clip/final-[key].vue?macro=true";
import { default as mine33vlSkRJ09Meta } from "/vercel/path0/pages/clip/mine.vue?macro=true";
import { default as preview_45_91key_93NMzxVTflR6Meta } from "/vercel/path0/pages/clip/preview-[key].vue?macro=true";
import { default as _91slug_93Qdu91GKCfpMeta } from "/vercel/path0/pages/connect/[slug].vue?macro=true";
import { default as minechXwkPJhOlMeta } from "/vercel/path0/pages/content/mine.vue?macro=true";
import { default as createRNGxInyJO7Meta } from "/vercel/path0/pages/create.vue?macro=true";
import { default as credit_45purchase_45successAxztuzVZNKMeta } from "/vercel/path0/pages/credit-purchase-success.vue?macro=true";
import { default as createjfg3oDb5N1Meta } from "/vercel/path0/pages/design/create.vue?macro=true";
import { default as minejPal5ljM58Meta } from "/vercel/path0/pages/design/mine.vue?macro=true";
import { default as _91slug_93bg85IcmEx3Meta } from "/vercel/path0/pages/editor/[slug].vue?macro=true";
import { default as _91key_93G6Xn0CB9gSMeta } from "/vercel/path0/pages/embed/[key].vue?macro=true";
import { default as testSPr3lHQMFpMeta } from "/vercel/path0/pages/embed/test.vue?macro=true";
import { default as _91id_93EBw3e5WRXxMeta } from "/vercel/path0/pages/episodes/[id].vue?macro=true";
import { default as faqsbR89DgHZwlMeta } from "/vercel/path0/pages/faqs.vue?macro=true";
import { default as free_45recorder_45toolURHgpeUHfXMeta } from "/vercel/path0/pages/free-recorder-tool.vue?macro=true";
import { default as free_45tools38ZvgRTc85Meta } from "/vercel/path0/pages/free-tools.vue?macro=true";
import { default as free_45transcription_45toolkdNUaQBrX6Meta } from "/vercel/path0/pages/free-transcription-tool.vue?macro=true";
import { default as gradientsiCF6RJEtlvMeta } from "/vercel/path0/pages/gradients.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as onboardingewPMQ0QeckMeta } from "/vercel/path0/pages/onboarding.vue?macro=true";
import { default as _91token_93Y1SABpq4rEMeta } from "/vercel/path0/pages/password-reset/confirm/[uid]/[token].vue?macro=true";
import { default as payment_45cancelPYhEBW8BJIMeta } from "/vercel/path0/pages/payment-cancel.vue?macro=true";
import { default as payment_45successAGfXBekK7fMeta } from "/vercel/path0/pages/payment-success.vue?macro=true";
import { default as podcastspFORAEWMrfMeta } from "/vercel/path0/pages/podcasts.vue?macro=true";
import { default as pricingIYNdvfoSO6Meta } from "/vercel/path0/pages/pricing.vue?macro=true";
import { default as _91type_93WRnDy6FAqyMeta } from "/vercel/path0/pages/purchase-credits/[type].vue?macro=true";
import { default as recordkrEjRpuW0KMeta } from "/vercel/path0/pages/record.vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as roadmap9jmg3pAyq3Meta } from "/vercel/path0/pages/roadmap.vue?macro=true";
import { default as signin0XCmIKo45IMeta } from "/vercel/path0/pages/signin.vue?macro=true";
import { default as signupY93ILdpGpbMeta } from "/vercel/path0/pages/signup.vue?macro=true";
import { default as supportJTqUQCOdxvMeta } from "/vercel/path0/pages/support.vue?macro=true";
import { default as _91slug_93BjLa7yd6yyMeta } from "/vercel/path0/pages/template/[slug].vue?macro=true";
import { default as text_45to_45speechBJsXTYRsJeMeta } from "/vercel/path0/pages/text-to-speech.vue?macro=true";
import { default as _91key_93ND7sIEocuOMeta } from "/vercel/path0/pages/tts-scripts/[key].vue?macro=true";
import { default as mineEbZPHbliNPMeta } from "/vercel/path0/pages/tts-scripts/mine.vue?macro=true";
import { default as upload_45audio122HNptPedMeta } from "/vercel/path0/pages/upload-audio.vue?macro=true";
import { default as _91key_93HlPIIbmFTmMeta } from "/vercel/path0/pages/view/[key].vue?macro=true";
export default [
  {
    name: _91slug_93RyVVoKkmcHMeta?.name ?? "slug___en-au",
    path: _91slug_93RyVVoKkmcHMeta?.path ?? "/:slug()",
    meta: _91slug_93RyVVoKkmcHMeta || {},
    alias: _91slug_93RyVVoKkmcHMeta?.alias || [],
    redirect: _91slug_93RyVVoKkmcHMeta?.redirect,
    component: () => import("/vercel/path0/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RyVVoKkmcHMeta?.name ?? "slug___en-us",
    path: _91slug_93RyVVoKkmcHMeta?.path ?? "/en-us/:slug()",
    meta: _91slug_93RyVVoKkmcHMeta || {},
    alias: _91slug_93RyVVoKkmcHMeta?.alias || [],
    redirect: _91slug_93RyVVoKkmcHMeta?.redirect,
    component: () => import("/vercel/path0/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RyVVoKkmcHMeta?.name ?? "slug___de",
    path: _91slug_93RyVVoKkmcHMeta?.path ?? "/de/:slug()",
    meta: _91slug_93RyVVoKkmcHMeta || {},
    alias: _91slug_93RyVVoKkmcHMeta?.alias || [],
    redirect: _91slug_93RyVVoKkmcHMeta?.redirect,
    component: () => import("/vercel/path0/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RyVVoKkmcHMeta?.name ?? "slug___fr",
    path: _91slug_93RyVVoKkmcHMeta?.path ?? "/fr/:slug()",
    meta: _91slug_93RyVVoKkmcHMeta || {},
    alias: _91slug_93RyVVoKkmcHMeta?.alias || [],
    redirect: _91slug_93RyVVoKkmcHMeta?.redirect,
    component: () => import("/vercel/path0/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RyVVoKkmcHMeta?.name ?? "slug___ja",
    path: _91slug_93RyVVoKkmcHMeta?.path ?? "/ja/:slug()",
    meta: _91slug_93RyVVoKkmcHMeta || {},
    alias: _91slug_93RyVVoKkmcHMeta?.alias || [],
    redirect: _91slug_93RyVVoKkmcHMeta?.redirect,
    component: () => import("/vercel/path0/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RyVVoKkmcHMeta?.name ?? "slug___es",
    path: _91slug_93RyVVoKkmcHMeta?.path ?? "/es/:slug()",
    meta: _91slug_93RyVVoKkmcHMeta || {},
    alias: _91slug_93RyVVoKkmcHMeta?.alias || [],
    redirect: _91slug_93RyVVoKkmcHMeta?.redirect,
    component: () => import("/vercel/path0/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: accountLUTTRGMhg3Meta?.name ?? "account___en-au",
    path: accountLUTTRGMhg3Meta?.path ?? "/account",
    meta: accountLUTTRGMhg3Meta || {},
    alias: accountLUTTRGMhg3Meta?.alias || [],
    redirect: accountLUTTRGMhg3Meta?.redirect,
    component: () => import("/vercel/path0/pages/account.vue").then(m => m.default || m)
  },
  {
    name: accountLUTTRGMhg3Meta?.name ?? "account___en-us",
    path: accountLUTTRGMhg3Meta?.path ?? "/en-us/account",
    meta: accountLUTTRGMhg3Meta || {},
    alias: accountLUTTRGMhg3Meta?.alias || [],
    redirect: accountLUTTRGMhg3Meta?.redirect,
    component: () => import("/vercel/path0/pages/account.vue").then(m => m.default || m)
  },
  {
    name: accountLUTTRGMhg3Meta?.name ?? "account___de",
    path: accountLUTTRGMhg3Meta?.path ?? "/de/account",
    meta: accountLUTTRGMhg3Meta || {},
    alias: accountLUTTRGMhg3Meta?.alias || [],
    redirect: accountLUTTRGMhg3Meta?.redirect,
    component: () => import("/vercel/path0/pages/account.vue").then(m => m.default || m)
  },
  {
    name: accountLUTTRGMhg3Meta?.name ?? "account___fr",
    path: accountLUTTRGMhg3Meta?.path ?? "/fr/account",
    meta: accountLUTTRGMhg3Meta || {},
    alias: accountLUTTRGMhg3Meta?.alias || [],
    redirect: accountLUTTRGMhg3Meta?.redirect,
    component: () => import("/vercel/path0/pages/account.vue").then(m => m.default || m)
  },
  {
    name: accountLUTTRGMhg3Meta?.name ?? "account___ja",
    path: accountLUTTRGMhg3Meta?.path ?? "/ja/account",
    meta: accountLUTTRGMhg3Meta || {},
    alias: accountLUTTRGMhg3Meta?.alias || [],
    redirect: accountLUTTRGMhg3Meta?.redirect,
    component: () => import("/vercel/path0/pages/account.vue").then(m => m.default || m)
  },
  {
    name: accountLUTTRGMhg3Meta?.name ?? "account___es",
    path: accountLUTTRGMhg3Meta?.path ?? "/es/account",
    meta: accountLUTTRGMhg3Meta || {},
    alias: accountLUTTRGMhg3Meta?.alias || [],
    redirect: accountLUTTRGMhg3Meta?.redirect,
    component: () => import("/vercel/path0/pages/account.vue").then(m => m.default || m)
  },
  {
    name: _91key_93kSGHgAtKuLMeta?.name ?? "accounts-confirm-email-key___en-au",
    path: _91key_93kSGHgAtKuLMeta?.path ?? "/accounts/confirm-email/:key()",
    meta: _91key_93kSGHgAtKuLMeta || {},
    alias: _91key_93kSGHgAtKuLMeta?.alias || [],
    redirect: _91key_93kSGHgAtKuLMeta?.redirect,
    component: () => import("/vercel/path0/pages/accounts/confirm-email/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93kSGHgAtKuLMeta?.name ?? "accounts-confirm-email-key___en-us",
    path: _91key_93kSGHgAtKuLMeta?.path ?? "/en-us/accounts/confirm-email/:key()",
    meta: _91key_93kSGHgAtKuLMeta || {},
    alias: _91key_93kSGHgAtKuLMeta?.alias || [],
    redirect: _91key_93kSGHgAtKuLMeta?.redirect,
    component: () => import("/vercel/path0/pages/accounts/confirm-email/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93kSGHgAtKuLMeta?.name ?? "accounts-confirm-email-key___de",
    path: _91key_93kSGHgAtKuLMeta?.path ?? "/de/accounts/confirm-email/:key()",
    meta: _91key_93kSGHgAtKuLMeta || {},
    alias: _91key_93kSGHgAtKuLMeta?.alias || [],
    redirect: _91key_93kSGHgAtKuLMeta?.redirect,
    component: () => import("/vercel/path0/pages/accounts/confirm-email/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93kSGHgAtKuLMeta?.name ?? "accounts-confirm-email-key___fr",
    path: _91key_93kSGHgAtKuLMeta?.path ?? "/fr/accounts/confirm-email/:key()",
    meta: _91key_93kSGHgAtKuLMeta || {},
    alias: _91key_93kSGHgAtKuLMeta?.alias || [],
    redirect: _91key_93kSGHgAtKuLMeta?.redirect,
    component: () => import("/vercel/path0/pages/accounts/confirm-email/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93kSGHgAtKuLMeta?.name ?? "accounts-confirm-email-key___ja",
    path: _91key_93kSGHgAtKuLMeta?.path ?? "/ja/accounts/confirm-email/:key()",
    meta: _91key_93kSGHgAtKuLMeta || {},
    alias: _91key_93kSGHgAtKuLMeta?.alias || [],
    redirect: _91key_93kSGHgAtKuLMeta?.redirect,
    component: () => import("/vercel/path0/pages/accounts/confirm-email/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93kSGHgAtKuLMeta?.name ?? "accounts-confirm-email-key___es",
    path: _91key_93kSGHgAtKuLMeta?.path ?? "/es/accounts/confirm-email/:key()",
    meta: _91key_93kSGHgAtKuLMeta || {},
    alias: _91key_93kSGHgAtKuLMeta?.alias || [],
    redirect: _91key_93kSGHgAtKuLMeta?.redirect,
    component: () => import("/vercel/path0/pages/accounts/confirm-email/[key].vue").then(m => m.default || m)
  },
  {
    name: affiliatess6GrbePN4RMeta?.name ?? "affiliates___en-au",
    path: affiliatess6GrbePN4RMeta?.path ?? "/affiliates",
    meta: affiliatess6GrbePN4RMeta || {},
    alias: affiliatess6GrbePN4RMeta?.alias || [],
    redirect: affiliatess6GrbePN4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: affiliatess6GrbePN4RMeta?.name ?? "affiliates___en-us",
    path: affiliatess6GrbePN4RMeta?.path ?? "/en-us/affiliates",
    meta: affiliatess6GrbePN4RMeta || {},
    alias: affiliatess6GrbePN4RMeta?.alias || [],
    redirect: affiliatess6GrbePN4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: affiliatess6GrbePN4RMeta?.name ?? "affiliates___de",
    path: affiliatess6GrbePN4RMeta?.path ?? "/de/affiliates",
    meta: affiliatess6GrbePN4RMeta || {},
    alias: affiliatess6GrbePN4RMeta?.alias || [],
    redirect: affiliatess6GrbePN4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: affiliatess6GrbePN4RMeta?.name ?? "affiliates___fr",
    path: affiliatess6GrbePN4RMeta?.path ?? "/fr/affiliates",
    meta: affiliatess6GrbePN4RMeta || {},
    alias: affiliatess6GrbePN4RMeta?.alias || [],
    redirect: affiliatess6GrbePN4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: affiliatess6GrbePN4RMeta?.name ?? "affiliates___ja",
    path: affiliatess6GrbePN4RMeta?.path ?? "/ja/affiliates",
    meta: affiliatess6GrbePN4RMeta || {},
    alias: affiliatess6GrbePN4RMeta?.alias || [],
    redirect: affiliatess6GrbePN4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: affiliatess6GrbePN4RMeta?.name ?? "affiliates___es",
    path: affiliatess6GrbePN4RMeta?.path ?? "/es/affiliates",
    meta: affiliatess6GrbePN4RMeta || {},
    alias: affiliatess6GrbePN4RMeta?.alias || [],
    redirect: affiliatess6GrbePN4RMeta?.redirect,
    component: () => import("/vercel/path0/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: appsumogaQQbdHfPWMeta?.name ?? "appsumo___en-au",
    path: appsumogaQQbdHfPWMeta?.path ?? "/appsumo",
    meta: appsumogaQQbdHfPWMeta || {},
    alias: appsumogaQQbdHfPWMeta?.alias || [],
    redirect: appsumogaQQbdHfPWMeta?.redirect,
    component: () => import("/vercel/path0/pages/appsumo.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93jdlVCCdpkFMeta?.name ?? "appsumo-slug___en-au",
    path: _91slug_93jdlVCCdpkFMeta?.path ?? ":slug()",
    meta: _91slug_93jdlVCCdpkFMeta || {},
    alias: _91slug_93jdlVCCdpkFMeta?.alias || [],
    redirect: _91slug_93jdlVCCdpkFMeta?.redirect,
    component: () => import("/vercel/path0/pages/appsumo/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: appsumogaQQbdHfPWMeta?.name ?? "appsumo___en-us",
    path: appsumogaQQbdHfPWMeta?.path ?? "/en-us/appsumo",
    meta: appsumogaQQbdHfPWMeta || {},
    alias: appsumogaQQbdHfPWMeta?.alias || [],
    redirect: appsumogaQQbdHfPWMeta?.redirect,
    component: () => import("/vercel/path0/pages/appsumo.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93jdlVCCdpkFMeta?.name ?? "appsumo-slug___en-us",
    path: _91slug_93jdlVCCdpkFMeta?.path ?? ":slug()",
    meta: _91slug_93jdlVCCdpkFMeta || {},
    alias: _91slug_93jdlVCCdpkFMeta?.alias || [],
    redirect: _91slug_93jdlVCCdpkFMeta?.redirect,
    component: () => import("/vercel/path0/pages/appsumo/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: appsumogaQQbdHfPWMeta?.name ?? "appsumo___de",
    path: appsumogaQQbdHfPWMeta?.path ?? "/de/appsumo",
    meta: appsumogaQQbdHfPWMeta || {},
    alias: appsumogaQQbdHfPWMeta?.alias || [],
    redirect: appsumogaQQbdHfPWMeta?.redirect,
    component: () => import("/vercel/path0/pages/appsumo.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93jdlVCCdpkFMeta?.name ?? "appsumo-slug___de",
    path: _91slug_93jdlVCCdpkFMeta?.path ?? ":slug()",
    meta: _91slug_93jdlVCCdpkFMeta || {},
    alias: _91slug_93jdlVCCdpkFMeta?.alias || [],
    redirect: _91slug_93jdlVCCdpkFMeta?.redirect,
    component: () => import("/vercel/path0/pages/appsumo/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: appsumogaQQbdHfPWMeta?.name ?? "appsumo___fr",
    path: appsumogaQQbdHfPWMeta?.path ?? "/fr/appsumo",
    meta: appsumogaQQbdHfPWMeta || {},
    alias: appsumogaQQbdHfPWMeta?.alias || [],
    redirect: appsumogaQQbdHfPWMeta?.redirect,
    component: () => import("/vercel/path0/pages/appsumo.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93jdlVCCdpkFMeta?.name ?? "appsumo-slug___fr",
    path: _91slug_93jdlVCCdpkFMeta?.path ?? ":slug()",
    meta: _91slug_93jdlVCCdpkFMeta || {},
    alias: _91slug_93jdlVCCdpkFMeta?.alias || [],
    redirect: _91slug_93jdlVCCdpkFMeta?.redirect,
    component: () => import("/vercel/path0/pages/appsumo/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: appsumogaQQbdHfPWMeta?.name ?? "appsumo___ja",
    path: appsumogaQQbdHfPWMeta?.path ?? "/ja/appsumo",
    meta: appsumogaQQbdHfPWMeta || {},
    alias: appsumogaQQbdHfPWMeta?.alias || [],
    redirect: appsumogaQQbdHfPWMeta?.redirect,
    component: () => import("/vercel/path0/pages/appsumo.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93jdlVCCdpkFMeta?.name ?? "appsumo-slug___ja",
    path: _91slug_93jdlVCCdpkFMeta?.path ?? ":slug()",
    meta: _91slug_93jdlVCCdpkFMeta || {},
    alias: _91slug_93jdlVCCdpkFMeta?.alias || [],
    redirect: _91slug_93jdlVCCdpkFMeta?.redirect,
    component: () => import("/vercel/path0/pages/appsumo/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: appsumogaQQbdHfPWMeta?.name ?? "appsumo___es",
    path: appsumogaQQbdHfPWMeta?.path ?? "/es/appsumo",
    meta: appsumogaQQbdHfPWMeta || {},
    alias: appsumogaQQbdHfPWMeta?.alias || [],
    redirect: appsumogaQQbdHfPWMeta?.redirect,
    component: () => import("/vercel/path0/pages/appsumo.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93jdlVCCdpkFMeta?.name ?? "appsumo-slug___es",
    path: _91slug_93jdlVCCdpkFMeta?.path ?? ":slug()",
    meta: _91slug_93jdlVCCdpkFMeta || {},
    alias: _91slug_93jdlVCCdpkFMeta?.alias || [],
    redirect: _91slug_93jdlVCCdpkFMeta?.redirect,
    component: () => import("/vercel/path0/pages/appsumo/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: articlesSToQQf8MXRMeta?.name ?? "articles___en-au",
    path: articlesSToQQf8MXRMeta?.path ?? "/articles",
    meta: articlesSToQQf8MXRMeta || {},
    alias: articlesSToQQf8MXRMeta?.alias || [],
    redirect: articlesSToQQf8MXRMeta?.redirect,
    component: () => import("/vercel/path0/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: articlesSToQQf8MXRMeta?.name ?? "articles___en-us",
    path: articlesSToQQf8MXRMeta?.path ?? "/en-us/articles",
    meta: articlesSToQQf8MXRMeta || {},
    alias: articlesSToQQf8MXRMeta?.alias || [],
    redirect: articlesSToQQf8MXRMeta?.redirect,
    component: () => import("/vercel/path0/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: articlesSToQQf8MXRMeta?.name ?? "articles___de",
    path: articlesSToQQf8MXRMeta?.path ?? "/de/articles",
    meta: articlesSToQQf8MXRMeta || {},
    alias: articlesSToQQf8MXRMeta?.alias || [],
    redirect: articlesSToQQf8MXRMeta?.redirect,
    component: () => import("/vercel/path0/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: articlesSToQQf8MXRMeta?.name ?? "articles___fr",
    path: articlesSToQQf8MXRMeta?.path ?? "/fr/articles",
    meta: articlesSToQQf8MXRMeta || {},
    alias: articlesSToQQf8MXRMeta?.alias || [],
    redirect: articlesSToQQf8MXRMeta?.redirect,
    component: () => import("/vercel/path0/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: articlesSToQQf8MXRMeta?.name ?? "articles___ja",
    path: articlesSToQQf8MXRMeta?.path ?? "/ja/articles",
    meta: articlesSToQQf8MXRMeta || {},
    alias: articlesSToQQf8MXRMeta?.alias || [],
    redirect: articlesSToQQf8MXRMeta?.redirect,
    component: () => import("/vercel/path0/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: articlesSToQQf8MXRMeta?.name ?? "articles___es",
    path: articlesSToQQf8MXRMeta?.path ?? "/es/articles",
    meta: articlesSToQQf8MXRMeta || {},
    alias: articlesSToQQf8MXRMeta?.alias || [],
    redirect: articlesSToQQf8MXRMeta?.redirect,
    component: () => import("/vercel/path0/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: mineQ3lQc6ywlTMeta?.name ?? "assets-mine___en-au",
    path: mineQ3lQc6ywlTMeta?.path ?? "/assets/mine",
    meta: mineQ3lQc6ywlTMeta || {},
    alias: mineQ3lQc6ywlTMeta?.alias || [],
    redirect: mineQ3lQc6ywlTMeta?.redirect,
    component: () => import("/vercel/path0/pages/assets/mine.vue").then(m => m.default || m)
  },
  {
    name: mineQ3lQc6ywlTMeta?.name ?? "assets-mine___en-us",
    path: mineQ3lQc6ywlTMeta?.path ?? "/en-us/assets/mine",
    meta: mineQ3lQc6ywlTMeta || {},
    alias: mineQ3lQc6ywlTMeta?.alias || [],
    redirect: mineQ3lQc6ywlTMeta?.redirect,
    component: () => import("/vercel/path0/pages/assets/mine.vue").then(m => m.default || m)
  },
  {
    name: mineQ3lQc6ywlTMeta?.name ?? "assets-mine___de",
    path: mineQ3lQc6ywlTMeta?.path ?? "/de/assets/mine",
    meta: mineQ3lQc6ywlTMeta || {},
    alias: mineQ3lQc6ywlTMeta?.alias || [],
    redirect: mineQ3lQc6ywlTMeta?.redirect,
    component: () => import("/vercel/path0/pages/assets/mine.vue").then(m => m.default || m)
  },
  {
    name: mineQ3lQc6ywlTMeta?.name ?? "assets-mine___fr",
    path: mineQ3lQc6ywlTMeta?.path ?? "/fr/assets/mine",
    meta: mineQ3lQc6ywlTMeta || {},
    alias: mineQ3lQc6ywlTMeta?.alias || [],
    redirect: mineQ3lQc6ywlTMeta?.redirect,
    component: () => import("/vercel/path0/pages/assets/mine.vue").then(m => m.default || m)
  },
  {
    name: mineQ3lQc6ywlTMeta?.name ?? "assets-mine___ja",
    path: mineQ3lQc6ywlTMeta?.path ?? "/ja/assets/mine",
    meta: mineQ3lQc6ywlTMeta || {},
    alias: mineQ3lQc6ywlTMeta?.alias || [],
    redirect: mineQ3lQc6ywlTMeta?.redirect,
    component: () => import("/vercel/path0/pages/assets/mine.vue").then(m => m.default || m)
  },
  {
    name: mineQ3lQc6ywlTMeta?.name ?? "assets-mine___es",
    path: mineQ3lQc6ywlTMeta?.path ?? "/es/assets/mine",
    meta: mineQ3lQc6ywlTMeta || {},
    alias: mineQ3lQc6ywlTMeta?.alias || [],
    redirect: mineQ3lQc6ywlTMeta?.redirect,
    component: () => import("/vercel/path0/pages/assets/mine.vue").then(m => m.default || m)
  },
  {
    name: _91key_93e19YskBsh7Meta?.name ?? "audio-key___en-au",
    path: _91key_93e19YskBsh7Meta?.path ?? "/audio/:key()",
    meta: _91key_93e19YskBsh7Meta || {},
    alias: _91key_93e19YskBsh7Meta?.alias || [],
    redirect: _91key_93e19YskBsh7Meta?.redirect,
    component: () => import("/vercel/path0/pages/audio/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93e19YskBsh7Meta?.name ?? "audio-key___en-us",
    path: _91key_93e19YskBsh7Meta?.path ?? "/en-us/audio/:key()",
    meta: _91key_93e19YskBsh7Meta || {},
    alias: _91key_93e19YskBsh7Meta?.alias || [],
    redirect: _91key_93e19YskBsh7Meta?.redirect,
    component: () => import("/vercel/path0/pages/audio/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93e19YskBsh7Meta?.name ?? "audio-key___de",
    path: _91key_93e19YskBsh7Meta?.path ?? "/de/audio/:key()",
    meta: _91key_93e19YskBsh7Meta || {},
    alias: _91key_93e19YskBsh7Meta?.alias || [],
    redirect: _91key_93e19YskBsh7Meta?.redirect,
    component: () => import("/vercel/path0/pages/audio/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93e19YskBsh7Meta?.name ?? "audio-key___fr",
    path: _91key_93e19YskBsh7Meta?.path ?? "/fr/audio/:key()",
    meta: _91key_93e19YskBsh7Meta || {},
    alias: _91key_93e19YskBsh7Meta?.alias || [],
    redirect: _91key_93e19YskBsh7Meta?.redirect,
    component: () => import("/vercel/path0/pages/audio/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93e19YskBsh7Meta?.name ?? "audio-key___ja",
    path: _91key_93e19YskBsh7Meta?.path ?? "/ja/audio/:key()",
    meta: _91key_93e19YskBsh7Meta || {},
    alias: _91key_93e19YskBsh7Meta?.alias || [],
    redirect: _91key_93e19YskBsh7Meta?.redirect,
    component: () => import("/vercel/path0/pages/audio/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93e19YskBsh7Meta?.name ?? "audio-key___es",
    path: _91key_93e19YskBsh7Meta?.path ?? "/es/audio/:key()",
    meta: _91key_93e19YskBsh7Meta || {},
    alias: _91key_93e19YskBsh7Meta?.alias || [],
    redirect: _91key_93e19YskBsh7Meta?.redirect,
    component: () => import("/vercel/path0/pages/audio/[key].vue").then(m => m.default || m)
  },
  {
    name: clipsmxvoTF3GEKMeta?.name ?? "audio-clips___en-au",
    path: clipsmxvoTF3GEKMeta?.path ?? "/audio/clips",
    meta: clipsmxvoTF3GEKMeta || {},
    alias: clipsmxvoTF3GEKMeta?.alias || [],
    redirect: clipsmxvoTF3GEKMeta?.redirect,
    component: () => import("/vercel/path0/pages/audio/clips.vue").then(m => m.default || m)
  },
  {
    name: clipsmxvoTF3GEKMeta?.name ?? "audio-clips___en-us",
    path: clipsmxvoTF3GEKMeta?.path ?? "/en-us/audio/clips",
    meta: clipsmxvoTF3GEKMeta || {},
    alias: clipsmxvoTF3GEKMeta?.alias || [],
    redirect: clipsmxvoTF3GEKMeta?.redirect,
    component: () => import("/vercel/path0/pages/audio/clips.vue").then(m => m.default || m)
  },
  {
    name: clipsmxvoTF3GEKMeta?.name ?? "audio-clips___de",
    path: clipsmxvoTF3GEKMeta?.path ?? "/de/audio/clips",
    meta: clipsmxvoTF3GEKMeta || {},
    alias: clipsmxvoTF3GEKMeta?.alias || [],
    redirect: clipsmxvoTF3GEKMeta?.redirect,
    component: () => import("/vercel/path0/pages/audio/clips.vue").then(m => m.default || m)
  },
  {
    name: clipsmxvoTF3GEKMeta?.name ?? "audio-clips___fr",
    path: clipsmxvoTF3GEKMeta?.path ?? "/fr/audio/clips",
    meta: clipsmxvoTF3GEKMeta || {},
    alias: clipsmxvoTF3GEKMeta?.alias || [],
    redirect: clipsmxvoTF3GEKMeta?.redirect,
    component: () => import("/vercel/path0/pages/audio/clips.vue").then(m => m.default || m)
  },
  {
    name: clipsmxvoTF3GEKMeta?.name ?? "audio-clips___ja",
    path: clipsmxvoTF3GEKMeta?.path ?? "/ja/audio/clips",
    meta: clipsmxvoTF3GEKMeta || {},
    alias: clipsmxvoTF3GEKMeta?.alias || [],
    redirect: clipsmxvoTF3GEKMeta?.redirect,
    component: () => import("/vercel/path0/pages/audio/clips.vue").then(m => m.default || m)
  },
  {
    name: clipsmxvoTF3GEKMeta?.name ?? "audio-clips___es",
    path: clipsmxvoTF3GEKMeta?.path ?? "/es/audio/clips",
    meta: clipsmxvoTF3GEKMeta || {},
    alias: clipsmxvoTF3GEKMeta?.alias || [],
    redirect: clipsmxvoTF3GEKMeta?.redirect,
    component: () => import("/vercel/path0/pages/audio/clips.vue").then(m => m.default || m)
  },
  {
    name: uploadsIqLnQdp5SzMeta?.name ?? "audio-uploads___en-au",
    path: uploadsIqLnQdp5SzMeta?.path ?? "/audio/uploads",
    meta: uploadsIqLnQdp5SzMeta || {},
    alias: uploadsIqLnQdp5SzMeta?.alias || [],
    redirect: uploadsIqLnQdp5SzMeta?.redirect,
    component: () => import("/vercel/path0/pages/audio/uploads.vue").then(m => m.default || m)
  },
  {
    name: uploadsIqLnQdp5SzMeta?.name ?? "audio-uploads___en-us",
    path: uploadsIqLnQdp5SzMeta?.path ?? "/en-us/audio/uploads",
    meta: uploadsIqLnQdp5SzMeta || {},
    alias: uploadsIqLnQdp5SzMeta?.alias || [],
    redirect: uploadsIqLnQdp5SzMeta?.redirect,
    component: () => import("/vercel/path0/pages/audio/uploads.vue").then(m => m.default || m)
  },
  {
    name: uploadsIqLnQdp5SzMeta?.name ?? "audio-uploads___de",
    path: uploadsIqLnQdp5SzMeta?.path ?? "/de/audio/uploads",
    meta: uploadsIqLnQdp5SzMeta || {},
    alias: uploadsIqLnQdp5SzMeta?.alias || [],
    redirect: uploadsIqLnQdp5SzMeta?.redirect,
    component: () => import("/vercel/path0/pages/audio/uploads.vue").then(m => m.default || m)
  },
  {
    name: uploadsIqLnQdp5SzMeta?.name ?? "audio-uploads___fr",
    path: uploadsIqLnQdp5SzMeta?.path ?? "/fr/audio/uploads",
    meta: uploadsIqLnQdp5SzMeta || {},
    alias: uploadsIqLnQdp5SzMeta?.alias || [],
    redirect: uploadsIqLnQdp5SzMeta?.redirect,
    component: () => import("/vercel/path0/pages/audio/uploads.vue").then(m => m.default || m)
  },
  {
    name: uploadsIqLnQdp5SzMeta?.name ?? "audio-uploads___ja",
    path: uploadsIqLnQdp5SzMeta?.path ?? "/ja/audio/uploads",
    meta: uploadsIqLnQdp5SzMeta || {},
    alias: uploadsIqLnQdp5SzMeta?.alias || [],
    redirect: uploadsIqLnQdp5SzMeta?.redirect,
    component: () => import("/vercel/path0/pages/audio/uploads.vue").then(m => m.default || m)
  },
  {
    name: uploadsIqLnQdp5SzMeta?.name ?? "audio-uploads___es",
    path: uploadsIqLnQdp5SzMeta?.path ?? "/es/audio/uploads",
    meta: uploadsIqLnQdp5SzMeta || {},
    alias: uploadsIqLnQdp5SzMeta?.alias || [],
    redirect: uploadsIqLnQdp5SzMeta?.redirect,
    component: () => import("/vercel/path0/pages/audio/uploads.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RLVJqb0vXlMeta?.name ?? "auth-slug___en-au",
    path: _91slug_93RLVJqb0vXlMeta?.path ?? "/auth/:slug()",
    meta: _91slug_93RLVJqb0vXlMeta || {},
    alias: _91slug_93RLVJqb0vXlMeta?.alias || [],
    redirect: _91slug_93RLVJqb0vXlMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RLVJqb0vXlMeta?.name ?? "auth-slug___en-us",
    path: _91slug_93RLVJqb0vXlMeta?.path ?? "/en-us/auth/:slug()",
    meta: _91slug_93RLVJqb0vXlMeta || {},
    alias: _91slug_93RLVJqb0vXlMeta?.alias || [],
    redirect: _91slug_93RLVJqb0vXlMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RLVJqb0vXlMeta?.name ?? "auth-slug___de",
    path: _91slug_93RLVJqb0vXlMeta?.path ?? "/de/auth/:slug()",
    meta: _91slug_93RLVJqb0vXlMeta || {},
    alias: _91slug_93RLVJqb0vXlMeta?.alias || [],
    redirect: _91slug_93RLVJqb0vXlMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RLVJqb0vXlMeta?.name ?? "auth-slug___fr",
    path: _91slug_93RLVJqb0vXlMeta?.path ?? "/fr/auth/:slug()",
    meta: _91slug_93RLVJqb0vXlMeta || {},
    alias: _91slug_93RLVJqb0vXlMeta?.alias || [],
    redirect: _91slug_93RLVJqb0vXlMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RLVJqb0vXlMeta?.name ?? "auth-slug___ja",
    path: _91slug_93RLVJqb0vXlMeta?.path ?? "/ja/auth/:slug()",
    meta: _91slug_93RLVJqb0vXlMeta || {},
    alias: _91slug_93RLVJqb0vXlMeta?.alias || [],
    redirect: _91slug_93RLVJqb0vXlMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RLVJqb0vXlMeta?.name ?? "auth-slug___es",
    path: _91slug_93RLVJqb0vXlMeta?.path ?? "/es/auth/:slug()",
    meta: _91slug_93RLVJqb0vXlMeta || {},
    alias: _91slug_93RLVJqb0vXlMeta?.alias || [],
    redirect: _91slug_93RLVJqb0vXlMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Po03rnzIFtMeta?.name ?? "blog-slug___en-au",
    path: _91slug_93Po03rnzIFtMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93Po03rnzIFtMeta || {},
    alias: _91slug_93Po03rnzIFtMeta?.alias || [],
    redirect: _91slug_93Po03rnzIFtMeta?.redirect,
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Po03rnzIFtMeta?.name ?? "blog-slug___en-us",
    path: _91slug_93Po03rnzIFtMeta?.path ?? "/en-us/blog/:slug()",
    meta: _91slug_93Po03rnzIFtMeta || {},
    alias: _91slug_93Po03rnzIFtMeta?.alias || [],
    redirect: _91slug_93Po03rnzIFtMeta?.redirect,
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Po03rnzIFtMeta?.name ?? "blog-slug___de",
    path: _91slug_93Po03rnzIFtMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93Po03rnzIFtMeta || {},
    alias: _91slug_93Po03rnzIFtMeta?.alias || [],
    redirect: _91slug_93Po03rnzIFtMeta?.redirect,
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Po03rnzIFtMeta?.name ?? "blog-slug___fr",
    path: _91slug_93Po03rnzIFtMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93Po03rnzIFtMeta || {},
    alias: _91slug_93Po03rnzIFtMeta?.alias || [],
    redirect: _91slug_93Po03rnzIFtMeta?.redirect,
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Po03rnzIFtMeta?.name ?? "blog-slug___ja",
    path: _91slug_93Po03rnzIFtMeta?.path ?? "/ja/blog/:slug()",
    meta: _91slug_93Po03rnzIFtMeta || {},
    alias: _91slug_93Po03rnzIFtMeta?.alias || [],
    redirect: _91slug_93Po03rnzIFtMeta?.redirect,
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Po03rnzIFtMeta?.name ?? "blog-slug___es",
    path: _91slug_93Po03rnzIFtMeta?.path ?? "/es/blog/:slug()",
    meta: _91slug_93Po03rnzIFtMeta || {},
    alias: _91slug_93Po03rnzIFtMeta?.alias || [],
    redirect: _91slug_93Po03rnzIFtMeta?.redirect,
    component: () => import("/vercel/path0/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: checkoutlZQLvi17ODMeta?.name ?? "checkout___en-au",
    path: checkoutlZQLvi17ODMeta?.path ?? "/checkout",
    meta: checkoutlZQLvi17ODMeta || {},
    alias: checkoutlZQLvi17ODMeta?.alias || [],
    redirect: checkoutlZQLvi17ODMeta?.redirect,
    component: () => import("/vercel/path0/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutlZQLvi17ODMeta?.name ?? "checkout___en-us",
    path: checkoutlZQLvi17ODMeta?.path ?? "/en-us/checkout",
    meta: checkoutlZQLvi17ODMeta || {},
    alias: checkoutlZQLvi17ODMeta?.alias || [],
    redirect: checkoutlZQLvi17ODMeta?.redirect,
    component: () => import("/vercel/path0/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutlZQLvi17ODMeta?.name ?? "checkout___de",
    path: checkoutlZQLvi17ODMeta?.path ?? "/de/checkout",
    meta: checkoutlZQLvi17ODMeta || {},
    alias: checkoutlZQLvi17ODMeta?.alias || [],
    redirect: checkoutlZQLvi17ODMeta?.redirect,
    component: () => import("/vercel/path0/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutlZQLvi17ODMeta?.name ?? "checkout___fr",
    path: checkoutlZQLvi17ODMeta?.path ?? "/fr/checkout",
    meta: checkoutlZQLvi17ODMeta || {},
    alias: checkoutlZQLvi17ODMeta?.alias || [],
    redirect: checkoutlZQLvi17ODMeta?.redirect,
    component: () => import("/vercel/path0/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutlZQLvi17ODMeta?.name ?? "checkout___ja",
    path: checkoutlZQLvi17ODMeta?.path ?? "/ja/checkout",
    meta: checkoutlZQLvi17ODMeta || {},
    alias: checkoutlZQLvi17ODMeta?.alias || [],
    redirect: checkoutlZQLvi17ODMeta?.redirect,
    component: () => import("/vercel/path0/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutlZQLvi17ODMeta?.name ?? "checkout___es",
    path: checkoutlZQLvi17ODMeta?.path ?? "/es/checkout",
    meta: checkoutlZQLvi17ODMeta || {},
    alias: checkoutlZQLvi17ODMeta?.alias || [],
    redirect: checkoutlZQLvi17ODMeta?.redirect,
    component: () => import("/vercel/path0/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: confirm_45_91key_93NZ12eh0qGPMeta?.name ?? "clip-confirm-key___en-au",
    path: confirm_45_91key_93NZ12eh0qGPMeta?.path ?? "/clip/confirm-:key()",
    meta: confirm_45_91key_93NZ12eh0qGPMeta || {},
    alias: confirm_45_91key_93NZ12eh0qGPMeta?.alias || [],
    redirect: confirm_45_91key_93NZ12eh0qGPMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/confirm-[key].vue").then(m => m.default || m)
  },
  {
    name: confirm_45_91key_93NZ12eh0qGPMeta?.name ?? "clip-confirm-key___en-us",
    path: confirm_45_91key_93NZ12eh0qGPMeta?.path ?? "/en-us/clip/confirm-:key()",
    meta: confirm_45_91key_93NZ12eh0qGPMeta || {},
    alias: confirm_45_91key_93NZ12eh0qGPMeta?.alias || [],
    redirect: confirm_45_91key_93NZ12eh0qGPMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/confirm-[key].vue").then(m => m.default || m)
  },
  {
    name: confirm_45_91key_93NZ12eh0qGPMeta?.name ?? "clip-confirm-key___de",
    path: confirm_45_91key_93NZ12eh0qGPMeta?.path ?? "/de/clip/confirm-:key()",
    meta: confirm_45_91key_93NZ12eh0qGPMeta || {},
    alias: confirm_45_91key_93NZ12eh0qGPMeta?.alias || [],
    redirect: confirm_45_91key_93NZ12eh0qGPMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/confirm-[key].vue").then(m => m.default || m)
  },
  {
    name: confirm_45_91key_93NZ12eh0qGPMeta?.name ?? "clip-confirm-key___fr",
    path: confirm_45_91key_93NZ12eh0qGPMeta?.path ?? "/fr/clip/confirm-:key()",
    meta: confirm_45_91key_93NZ12eh0qGPMeta || {},
    alias: confirm_45_91key_93NZ12eh0qGPMeta?.alias || [],
    redirect: confirm_45_91key_93NZ12eh0qGPMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/confirm-[key].vue").then(m => m.default || m)
  },
  {
    name: confirm_45_91key_93NZ12eh0qGPMeta?.name ?? "clip-confirm-key___ja",
    path: confirm_45_91key_93NZ12eh0qGPMeta?.path ?? "/ja/clip/confirm-:key()",
    meta: confirm_45_91key_93NZ12eh0qGPMeta || {},
    alias: confirm_45_91key_93NZ12eh0qGPMeta?.alias || [],
    redirect: confirm_45_91key_93NZ12eh0qGPMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/confirm-[key].vue").then(m => m.default || m)
  },
  {
    name: confirm_45_91key_93NZ12eh0qGPMeta?.name ?? "clip-confirm-key___es",
    path: confirm_45_91key_93NZ12eh0qGPMeta?.path ?? "/es/clip/confirm-:key()",
    meta: confirm_45_91key_93NZ12eh0qGPMeta || {},
    alias: confirm_45_91key_93NZ12eh0qGPMeta?.alias || [],
    redirect: confirm_45_91key_93NZ12eh0qGPMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/confirm-[key].vue").then(m => m.default || m)
  },
  {
    name: design_45_91key_93jzJ9NWXMkQMeta?.name ?? "clip-design-key___en-au",
    path: design_45_91key_93jzJ9NWXMkQMeta?.path ?? "/clip/design-:key()",
    meta: design_45_91key_93jzJ9NWXMkQMeta || {},
    alias: design_45_91key_93jzJ9NWXMkQMeta?.alias || [],
    redirect: design_45_91key_93jzJ9NWXMkQMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/design-[key].vue").then(m => m.default || m)
  },
  {
    name: design_45_91key_93jzJ9NWXMkQMeta?.name ?? "clip-design-key___en-us",
    path: design_45_91key_93jzJ9NWXMkQMeta?.path ?? "/en-us/clip/design-:key()",
    meta: design_45_91key_93jzJ9NWXMkQMeta || {},
    alias: design_45_91key_93jzJ9NWXMkQMeta?.alias || [],
    redirect: design_45_91key_93jzJ9NWXMkQMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/design-[key].vue").then(m => m.default || m)
  },
  {
    name: design_45_91key_93jzJ9NWXMkQMeta?.name ?? "clip-design-key___de",
    path: design_45_91key_93jzJ9NWXMkQMeta?.path ?? "/de/clip/design-:key()",
    meta: design_45_91key_93jzJ9NWXMkQMeta || {},
    alias: design_45_91key_93jzJ9NWXMkQMeta?.alias || [],
    redirect: design_45_91key_93jzJ9NWXMkQMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/design-[key].vue").then(m => m.default || m)
  },
  {
    name: design_45_91key_93jzJ9NWXMkQMeta?.name ?? "clip-design-key___fr",
    path: design_45_91key_93jzJ9NWXMkQMeta?.path ?? "/fr/clip/design-:key()",
    meta: design_45_91key_93jzJ9NWXMkQMeta || {},
    alias: design_45_91key_93jzJ9NWXMkQMeta?.alias || [],
    redirect: design_45_91key_93jzJ9NWXMkQMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/design-[key].vue").then(m => m.default || m)
  },
  {
    name: design_45_91key_93jzJ9NWXMkQMeta?.name ?? "clip-design-key___ja",
    path: design_45_91key_93jzJ9NWXMkQMeta?.path ?? "/ja/clip/design-:key()",
    meta: design_45_91key_93jzJ9NWXMkQMeta || {},
    alias: design_45_91key_93jzJ9NWXMkQMeta?.alias || [],
    redirect: design_45_91key_93jzJ9NWXMkQMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/design-[key].vue").then(m => m.default || m)
  },
  {
    name: design_45_91key_93jzJ9NWXMkQMeta?.name ?? "clip-design-key___es",
    path: design_45_91key_93jzJ9NWXMkQMeta?.path ?? "/es/clip/design-:key()",
    meta: design_45_91key_93jzJ9NWXMkQMeta || {},
    alias: design_45_91key_93jzJ9NWXMkQMeta?.alias || [],
    redirect: design_45_91key_93jzJ9NWXMkQMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/design-[key].vue").then(m => m.default || m)
  },
  {
    name: final_45_91key_93vx9DVI2JTDMeta?.name ?? "clip-final-key___en-au",
    path: final_45_91key_93vx9DVI2JTDMeta?.path ?? "/clip/final-:key()",
    meta: final_45_91key_93vx9DVI2JTDMeta || {},
    alias: final_45_91key_93vx9DVI2JTDMeta?.alias || [],
    redirect: final_45_91key_93vx9DVI2JTDMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/final-[key].vue").then(m => m.default || m)
  },
  {
    name: final_45_91key_93vx9DVI2JTDMeta?.name ?? "clip-final-key___en-us",
    path: final_45_91key_93vx9DVI2JTDMeta?.path ?? "/en-us/clip/final-:key()",
    meta: final_45_91key_93vx9DVI2JTDMeta || {},
    alias: final_45_91key_93vx9DVI2JTDMeta?.alias || [],
    redirect: final_45_91key_93vx9DVI2JTDMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/final-[key].vue").then(m => m.default || m)
  },
  {
    name: final_45_91key_93vx9DVI2JTDMeta?.name ?? "clip-final-key___de",
    path: final_45_91key_93vx9DVI2JTDMeta?.path ?? "/de/clip/final-:key()",
    meta: final_45_91key_93vx9DVI2JTDMeta || {},
    alias: final_45_91key_93vx9DVI2JTDMeta?.alias || [],
    redirect: final_45_91key_93vx9DVI2JTDMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/final-[key].vue").then(m => m.default || m)
  },
  {
    name: final_45_91key_93vx9DVI2JTDMeta?.name ?? "clip-final-key___fr",
    path: final_45_91key_93vx9DVI2JTDMeta?.path ?? "/fr/clip/final-:key()",
    meta: final_45_91key_93vx9DVI2JTDMeta || {},
    alias: final_45_91key_93vx9DVI2JTDMeta?.alias || [],
    redirect: final_45_91key_93vx9DVI2JTDMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/final-[key].vue").then(m => m.default || m)
  },
  {
    name: final_45_91key_93vx9DVI2JTDMeta?.name ?? "clip-final-key___ja",
    path: final_45_91key_93vx9DVI2JTDMeta?.path ?? "/ja/clip/final-:key()",
    meta: final_45_91key_93vx9DVI2JTDMeta || {},
    alias: final_45_91key_93vx9DVI2JTDMeta?.alias || [],
    redirect: final_45_91key_93vx9DVI2JTDMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/final-[key].vue").then(m => m.default || m)
  },
  {
    name: final_45_91key_93vx9DVI2JTDMeta?.name ?? "clip-final-key___es",
    path: final_45_91key_93vx9DVI2JTDMeta?.path ?? "/es/clip/final-:key()",
    meta: final_45_91key_93vx9DVI2JTDMeta || {},
    alias: final_45_91key_93vx9DVI2JTDMeta?.alias || [],
    redirect: final_45_91key_93vx9DVI2JTDMeta?.redirect,
    component: () => import("/vercel/path0/pages/clip/final-[key].vue").then(m => m.default || m)
  },
  {
    name: mine33vlSkRJ09Meta?.name ?? "clip-mine___en-au",
    path: mine33vlSkRJ09Meta?.path ?? "/clip/mine",
    meta: mine33vlSkRJ09Meta || {},
    alias: mine33vlSkRJ09Meta?.alias || [],
    redirect: mine33vlSkRJ09Meta?.redirect,
    component: () => import("/vercel/path0/pages/clip/mine.vue").then(m => m.default || m)
  },
  {
    name: mine33vlSkRJ09Meta?.name ?? "clip-mine___en-us",
    path: mine33vlSkRJ09Meta?.path ?? "/en-us/clip/mine",
    meta: mine33vlSkRJ09Meta || {},
    alias: mine33vlSkRJ09Meta?.alias || [],
    redirect: mine33vlSkRJ09Meta?.redirect,
    component: () => import("/vercel/path0/pages/clip/mine.vue").then(m => m.default || m)
  },
  {
    name: mine33vlSkRJ09Meta?.name ?? "clip-mine___de",
    path: mine33vlSkRJ09Meta?.path ?? "/de/clip/mine",
    meta: mine33vlSkRJ09Meta || {},
    alias: mine33vlSkRJ09Meta?.alias || [],
    redirect: mine33vlSkRJ09Meta?.redirect,
    component: () => import("/vercel/path0/pages/clip/mine.vue").then(m => m.default || m)
  },
  {
    name: mine33vlSkRJ09Meta?.name ?? "clip-mine___fr",
    path: mine33vlSkRJ09Meta?.path ?? "/fr/clip/mine",
    meta: mine33vlSkRJ09Meta || {},
    alias: mine33vlSkRJ09Meta?.alias || [],
    redirect: mine33vlSkRJ09Meta?.redirect,
    component: () => import("/vercel/path0/pages/clip/mine.vue").then(m => m.default || m)
  },
  {
    name: mine33vlSkRJ09Meta?.name ?? "clip-mine___ja",
    path: mine33vlSkRJ09Meta?.path ?? "/ja/clip/mine",
    meta: mine33vlSkRJ09Meta || {},
    alias: mine33vlSkRJ09Meta?.alias || [],
    redirect: mine33vlSkRJ09Meta?.redirect,
    component: () => import("/vercel/path0/pages/clip/mine.vue").then(m => m.default || m)
  },
  {
    name: mine33vlSkRJ09Meta?.name ?? "clip-mine___es",
    path: mine33vlSkRJ09Meta?.path ?? "/es/clip/mine",
    meta: mine33vlSkRJ09Meta || {},
    alias: mine33vlSkRJ09Meta?.alias || [],
    redirect: mine33vlSkRJ09Meta?.redirect,
    component: () => import("/vercel/path0/pages/clip/mine.vue").then(m => m.default || m)
  },
  {
    name: preview_45_91key_93NMzxVTflR6Meta?.name ?? "clip-preview-key___en-au",
    path: preview_45_91key_93NMzxVTflR6Meta?.path ?? "/clip/preview-:key()",
    meta: preview_45_91key_93NMzxVTflR6Meta || {},
    alias: preview_45_91key_93NMzxVTflR6Meta?.alias || [],
    redirect: preview_45_91key_93NMzxVTflR6Meta?.redirect,
    component: () => import("/vercel/path0/pages/clip/preview-[key].vue").then(m => m.default || m)
  },
  {
    name: preview_45_91key_93NMzxVTflR6Meta?.name ?? "clip-preview-key___en-us",
    path: preview_45_91key_93NMzxVTflR6Meta?.path ?? "/en-us/clip/preview-:key()",
    meta: preview_45_91key_93NMzxVTflR6Meta || {},
    alias: preview_45_91key_93NMzxVTflR6Meta?.alias || [],
    redirect: preview_45_91key_93NMzxVTflR6Meta?.redirect,
    component: () => import("/vercel/path0/pages/clip/preview-[key].vue").then(m => m.default || m)
  },
  {
    name: preview_45_91key_93NMzxVTflR6Meta?.name ?? "clip-preview-key___de",
    path: preview_45_91key_93NMzxVTflR6Meta?.path ?? "/de/clip/preview-:key()",
    meta: preview_45_91key_93NMzxVTflR6Meta || {},
    alias: preview_45_91key_93NMzxVTflR6Meta?.alias || [],
    redirect: preview_45_91key_93NMzxVTflR6Meta?.redirect,
    component: () => import("/vercel/path0/pages/clip/preview-[key].vue").then(m => m.default || m)
  },
  {
    name: preview_45_91key_93NMzxVTflR6Meta?.name ?? "clip-preview-key___fr",
    path: preview_45_91key_93NMzxVTflR6Meta?.path ?? "/fr/clip/preview-:key()",
    meta: preview_45_91key_93NMzxVTflR6Meta || {},
    alias: preview_45_91key_93NMzxVTflR6Meta?.alias || [],
    redirect: preview_45_91key_93NMzxVTflR6Meta?.redirect,
    component: () => import("/vercel/path0/pages/clip/preview-[key].vue").then(m => m.default || m)
  },
  {
    name: preview_45_91key_93NMzxVTflR6Meta?.name ?? "clip-preview-key___ja",
    path: preview_45_91key_93NMzxVTflR6Meta?.path ?? "/ja/clip/preview-:key()",
    meta: preview_45_91key_93NMzxVTflR6Meta || {},
    alias: preview_45_91key_93NMzxVTflR6Meta?.alias || [],
    redirect: preview_45_91key_93NMzxVTflR6Meta?.redirect,
    component: () => import("/vercel/path0/pages/clip/preview-[key].vue").then(m => m.default || m)
  },
  {
    name: preview_45_91key_93NMzxVTflR6Meta?.name ?? "clip-preview-key___es",
    path: preview_45_91key_93NMzxVTflR6Meta?.path ?? "/es/clip/preview-:key()",
    meta: preview_45_91key_93NMzxVTflR6Meta || {},
    alias: preview_45_91key_93NMzxVTflR6Meta?.alias || [],
    redirect: preview_45_91key_93NMzxVTflR6Meta?.redirect,
    component: () => import("/vercel/path0/pages/clip/preview-[key].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qdu91GKCfpMeta?.name ?? "connect-slug___en-au",
    path: _91slug_93Qdu91GKCfpMeta?.path ?? "/connect/:slug()",
    meta: _91slug_93Qdu91GKCfpMeta || {},
    alias: _91slug_93Qdu91GKCfpMeta?.alias || [],
    redirect: _91slug_93Qdu91GKCfpMeta?.redirect,
    component: () => import("/vercel/path0/pages/connect/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qdu91GKCfpMeta?.name ?? "connect-slug___en-us",
    path: _91slug_93Qdu91GKCfpMeta?.path ?? "/en-us/connect/:slug()",
    meta: _91slug_93Qdu91GKCfpMeta || {},
    alias: _91slug_93Qdu91GKCfpMeta?.alias || [],
    redirect: _91slug_93Qdu91GKCfpMeta?.redirect,
    component: () => import("/vercel/path0/pages/connect/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qdu91GKCfpMeta?.name ?? "connect-slug___de",
    path: _91slug_93Qdu91GKCfpMeta?.path ?? "/de/connect/:slug()",
    meta: _91slug_93Qdu91GKCfpMeta || {},
    alias: _91slug_93Qdu91GKCfpMeta?.alias || [],
    redirect: _91slug_93Qdu91GKCfpMeta?.redirect,
    component: () => import("/vercel/path0/pages/connect/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qdu91GKCfpMeta?.name ?? "connect-slug___fr",
    path: _91slug_93Qdu91GKCfpMeta?.path ?? "/fr/connect/:slug()",
    meta: _91slug_93Qdu91GKCfpMeta || {},
    alias: _91slug_93Qdu91GKCfpMeta?.alias || [],
    redirect: _91slug_93Qdu91GKCfpMeta?.redirect,
    component: () => import("/vercel/path0/pages/connect/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qdu91GKCfpMeta?.name ?? "connect-slug___ja",
    path: _91slug_93Qdu91GKCfpMeta?.path ?? "/ja/connect/:slug()",
    meta: _91slug_93Qdu91GKCfpMeta || {},
    alias: _91slug_93Qdu91GKCfpMeta?.alias || [],
    redirect: _91slug_93Qdu91GKCfpMeta?.redirect,
    component: () => import("/vercel/path0/pages/connect/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qdu91GKCfpMeta?.name ?? "connect-slug___es",
    path: _91slug_93Qdu91GKCfpMeta?.path ?? "/es/connect/:slug()",
    meta: _91slug_93Qdu91GKCfpMeta || {},
    alias: _91slug_93Qdu91GKCfpMeta?.alias || [],
    redirect: _91slug_93Qdu91GKCfpMeta?.redirect,
    component: () => import("/vercel/path0/pages/connect/[slug].vue").then(m => m.default || m)
  },
  {
    name: minechXwkPJhOlMeta?.name ?? "content-mine___en-au",
    path: minechXwkPJhOlMeta?.path ?? "/content/mine",
    meta: minechXwkPJhOlMeta || {},
    alias: minechXwkPJhOlMeta?.alias || [],
    redirect: minechXwkPJhOlMeta?.redirect,
    component: () => import("/vercel/path0/pages/content/mine.vue").then(m => m.default || m)
  },
  {
    name: minechXwkPJhOlMeta?.name ?? "content-mine___en-us",
    path: minechXwkPJhOlMeta?.path ?? "/en-us/content/mine",
    meta: minechXwkPJhOlMeta || {},
    alias: minechXwkPJhOlMeta?.alias || [],
    redirect: minechXwkPJhOlMeta?.redirect,
    component: () => import("/vercel/path0/pages/content/mine.vue").then(m => m.default || m)
  },
  {
    name: minechXwkPJhOlMeta?.name ?? "content-mine___de",
    path: minechXwkPJhOlMeta?.path ?? "/de/content/mine",
    meta: minechXwkPJhOlMeta || {},
    alias: minechXwkPJhOlMeta?.alias || [],
    redirect: minechXwkPJhOlMeta?.redirect,
    component: () => import("/vercel/path0/pages/content/mine.vue").then(m => m.default || m)
  },
  {
    name: minechXwkPJhOlMeta?.name ?? "content-mine___fr",
    path: minechXwkPJhOlMeta?.path ?? "/fr/content/mine",
    meta: minechXwkPJhOlMeta || {},
    alias: minechXwkPJhOlMeta?.alias || [],
    redirect: minechXwkPJhOlMeta?.redirect,
    component: () => import("/vercel/path0/pages/content/mine.vue").then(m => m.default || m)
  },
  {
    name: minechXwkPJhOlMeta?.name ?? "content-mine___ja",
    path: minechXwkPJhOlMeta?.path ?? "/ja/content/mine",
    meta: minechXwkPJhOlMeta || {},
    alias: minechXwkPJhOlMeta?.alias || [],
    redirect: minechXwkPJhOlMeta?.redirect,
    component: () => import("/vercel/path0/pages/content/mine.vue").then(m => m.default || m)
  },
  {
    name: minechXwkPJhOlMeta?.name ?? "content-mine___es",
    path: minechXwkPJhOlMeta?.path ?? "/es/content/mine",
    meta: minechXwkPJhOlMeta || {},
    alias: minechXwkPJhOlMeta?.alias || [],
    redirect: minechXwkPJhOlMeta?.redirect,
    component: () => import("/vercel/path0/pages/content/mine.vue").then(m => m.default || m)
  },
  {
    name: createRNGxInyJO7Meta?.name ?? "create___en-au",
    path: createRNGxInyJO7Meta?.path ?? "/create",
    meta: createRNGxInyJO7Meta || {},
    alias: createRNGxInyJO7Meta?.alias || [],
    redirect: createRNGxInyJO7Meta?.redirect,
    component: () => import("/vercel/path0/pages/create.vue").then(m => m.default || m)
  },
  {
    name: createRNGxInyJO7Meta?.name ?? "create___en-us",
    path: createRNGxInyJO7Meta?.path ?? "/en-us/create",
    meta: createRNGxInyJO7Meta || {},
    alias: createRNGxInyJO7Meta?.alias || [],
    redirect: createRNGxInyJO7Meta?.redirect,
    component: () => import("/vercel/path0/pages/create.vue").then(m => m.default || m)
  },
  {
    name: createRNGxInyJO7Meta?.name ?? "create___de",
    path: createRNGxInyJO7Meta?.path ?? "/de/create",
    meta: createRNGxInyJO7Meta || {},
    alias: createRNGxInyJO7Meta?.alias || [],
    redirect: createRNGxInyJO7Meta?.redirect,
    component: () => import("/vercel/path0/pages/create.vue").then(m => m.default || m)
  },
  {
    name: createRNGxInyJO7Meta?.name ?? "create___fr",
    path: createRNGxInyJO7Meta?.path ?? "/fr/create",
    meta: createRNGxInyJO7Meta || {},
    alias: createRNGxInyJO7Meta?.alias || [],
    redirect: createRNGxInyJO7Meta?.redirect,
    component: () => import("/vercel/path0/pages/create.vue").then(m => m.default || m)
  },
  {
    name: createRNGxInyJO7Meta?.name ?? "create___ja",
    path: createRNGxInyJO7Meta?.path ?? "/ja/create",
    meta: createRNGxInyJO7Meta || {},
    alias: createRNGxInyJO7Meta?.alias || [],
    redirect: createRNGxInyJO7Meta?.redirect,
    component: () => import("/vercel/path0/pages/create.vue").then(m => m.default || m)
  },
  {
    name: createRNGxInyJO7Meta?.name ?? "create___es",
    path: createRNGxInyJO7Meta?.path ?? "/es/create",
    meta: createRNGxInyJO7Meta || {},
    alias: createRNGxInyJO7Meta?.alias || [],
    redirect: createRNGxInyJO7Meta?.redirect,
    component: () => import("/vercel/path0/pages/create.vue").then(m => m.default || m)
  },
  {
    name: credit_45purchase_45successAxztuzVZNKMeta?.name ?? "credit-purchase-success___en-au",
    path: credit_45purchase_45successAxztuzVZNKMeta?.path ?? "/credit-purchase-success",
    meta: credit_45purchase_45successAxztuzVZNKMeta || {},
    alias: credit_45purchase_45successAxztuzVZNKMeta?.alias || [],
    redirect: credit_45purchase_45successAxztuzVZNKMeta?.redirect,
    component: () => import("/vercel/path0/pages/credit-purchase-success.vue").then(m => m.default || m)
  },
  {
    name: credit_45purchase_45successAxztuzVZNKMeta?.name ?? "credit-purchase-success___en-us",
    path: credit_45purchase_45successAxztuzVZNKMeta?.path ?? "/en-us/credit-purchase-success",
    meta: credit_45purchase_45successAxztuzVZNKMeta || {},
    alias: credit_45purchase_45successAxztuzVZNKMeta?.alias || [],
    redirect: credit_45purchase_45successAxztuzVZNKMeta?.redirect,
    component: () => import("/vercel/path0/pages/credit-purchase-success.vue").then(m => m.default || m)
  },
  {
    name: credit_45purchase_45successAxztuzVZNKMeta?.name ?? "credit-purchase-success___de",
    path: credit_45purchase_45successAxztuzVZNKMeta?.path ?? "/de/credit-purchase-success",
    meta: credit_45purchase_45successAxztuzVZNKMeta || {},
    alias: credit_45purchase_45successAxztuzVZNKMeta?.alias || [],
    redirect: credit_45purchase_45successAxztuzVZNKMeta?.redirect,
    component: () => import("/vercel/path0/pages/credit-purchase-success.vue").then(m => m.default || m)
  },
  {
    name: credit_45purchase_45successAxztuzVZNKMeta?.name ?? "credit-purchase-success___fr",
    path: credit_45purchase_45successAxztuzVZNKMeta?.path ?? "/fr/credit-purchase-success",
    meta: credit_45purchase_45successAxztuzVZNKMeta || {},
    alias: credit_45purchase_45successAxztuzVZNKMeta?.alias || [],
    redirect: credit_45purchase_45successAxztuzVZNKMeta?.redirect,
    component: () => import("/vercel/path0/pages/credit-purchase-success.vue").then(m => m.default || m)
  },
  {
    name: credit_45purchase_45successAxztuzVZNKMeta?.name ?? "credit-purchase-success___ja",
    path: credit_45purchase_45successAxztuzVZNKMeta?.path ?? "/ja/credit-purchase-success",
    meta: credit_45purchase_45successAxztuzVZNKMeta || {},
    alias: credit_45purchase_45successAxztuzVZNKMeta?.alias || [],
    redirect: credit_45purchase_45successAxztuzVZNKMeta?.redirect,
    component: () => import("/vercel/path0/pages/credit-purchase-success.vue").then(m => m.default || m)
  },
  {
    name: credit_45purchase_45successAxztuzVZNKMeta?.name ?? "credit-purchase-success___es",
    path: credit_45purchase_45successAxztuzVZNKMeta?.path ?? "/es/credit-purchase-success",
    meta: credit_45purchase_45successAxztuzVZNKMeta || {},
    alias: credit_45purchase_45successAxztuzVZNKMeta?.alias || [],
    redirect: credit_45purchase_45successAxztuzVZNKMeta?.redirect,
    component: () => import("/vercel/path0/pages/credit-purchase-success.vue").then(m => m.default || m)
  },
  {
    name: createjfg3oDb5N1Meta?.name ?? "design-create___en-au",
    path: createjfg3oDb5N1Meta?.path ?? "/design/create",
    meta: createjfg3oDb5N1Meta || {},
    alias: createjfg3oDb5N1Meta?.alias || [],
    redirect: createjfg3oDb5N1Meta?.redirect,
    component: () => import("/vercel/path0/pages/design/create.vue").then(m => m.default || m)
  },
  {
    name: createjfg3oDb5N1Meta?.name ?? "design-create___en-us",
    path: createjfg3oDb5N1Meta?.path ?? "/en-us/design/create",
    meta: createjfg3oDb5N1Meta || {},
    alias: createjfg3oDb5N1Meta?.alias || [],
    redirect: createjfg3oDb5N1Meta?.redirect,
    component: () => import("/vercel/path0/pages/design/create.vue").then(m => m.default || m)
  },
  {
    name: createjfg3oDb5N1Meta?.name ?? "design-create___de",
    path: createjfg3oDb5N1Meta?.path ?? "/de/design/create",
    meta: createjfg3oDb5N1Meta || {},
    alias: createjfg3oDb5N1Meta?.alias || [],
    redirect: createjfg3oDb5N1Meta?.redirect,
    component: () => import("/vercel/path0/pages/design/create.vue").then(m => m.default || m)
  },
  {
    name: createjfg3oDb5N1Meta?.name ?? "design-create___fr",
    path: createjfg3oDb5N1Meta?.path ?? "/fr/design/create",
    meta: createjfg3oDb5N1Meta || {},
    alias: createjfg3oDb5N1Meta?.alias || [],
    redirect: createjfg3oDb5N1Meta?.redirect,
    component: () => import("/vercel/path0/pages/design/create.vue").then(m => m.default || m)
  },
  {
    name: createjfg3oDb5N1Meta?.name ?? "design-create___ja",
    path: createjfg3oDb5N1Meta?.path ?? "/ja/design/create",
    meta: createjfg3oDb5N1Meta || {},
    alias: createjfg3oDb5N1Meta?.alias || [],
    redirect: createjfg3oDb5N1Meta?.redirect,
    component: () => import("/vercel/path0/pages/design/create.vue").then(m => m.default || m)
  },
  {
    name: createjfg3oDb5N1Meta?.name ?? "design-create___es",
    path: createjfg3oDb5N1Meta?.path ?? "/es/design/create",
    meta: createjfg3oDb5N1Meta || {},
    alias: createjfg3oDb5N1Meta?.alias || [],
    redirect: createjfg3oDb5N1Meta?.redirect,
    component: () => import("/vercel/path0/pages/design/create.vue").then(m => m.default || m)
  },
  {
    name: minejPal5ljM58Meta?.name ?? "design-mine___en-au",
    path: minejPal5ljM58Meta?.path ?? "/design/mine",
    meta: minejPal5ljM58Meta || {},
    alias: minejPal5ljM58Meta?.alias || [],
    redirect: minejPal5ljM58Meta?.redirect,
    component: () => import("/vercel/path0/pages/design/mine.vue").then(m => m.default || m)
  },
  {
    name: minejPal5ljM58Meta?.name ?? "design-mine___en-us",
    path: minejPal5ljM58Meta?.path ?? "/en-us/design/mine",
    meta: minejPal5ljM58Meta || {},
    alias: minejPal5ljM58Meta?.alias || [],
    redirect: minejPal5ljM58Meta?.redirect,
    component: () => import("/vercel/path0/pages/design/mine.vue").then(m => m.default || m)
  },
  {
    name: minejPal5ljM58Meta?.name ?? "design-mine___de",
    path: minejPal5ljM58Meta?.path ?? "/de/design/mine",
    meta: minejPal5ljM58Meta || {},
    alias: minejPal5ljM58Meta?.alias || [],
    redirect: minejPal5ljM58Meta?.redirect,
    component: () => import("/vercel/path0/pages/design/mine.vue").then(m => m.default || m)
  },
  {
    name: minejPal5ljM58Meta?.name ?? "design-mine___fr",
    path: minejPal5ljM58Meta?.path ?? "/fr/design/mine",
    meta: minejPal5ljM58Meta || {},
    alias: minejPal5ljM58Meta?.alias || [],
    redirect: minejPal5ljM58Meta?.redirect,
    component: () => import("/vercel/path0/pages/design/mine.vue").then(m => m.default || m)
  },
  {
    name: minejPal5ljM58Meta?.name ?? "design-mine___ja",
    path: minejPal5ljM58Meta?.path ?? "/ja/design/mine",
    meta: minejPal5ljM58Meta || {},
    alias: minejPal5ljM58Meta?.alias || [],
    redirect: minejPal5ljM58Meta?.redirect,
    component: () => import("/vercel/path0/pages/design/mine.vue").then(m => m.default || m)
  },
  {
    name: minejPal5ljM58Meta?.name ?? "design-mine___es",
    path: minejPal5ljM58Meta?.path ?? "/es/design/mine",
    meta: minejPal5ljM58Meta || {},
    alias: minejPal5ljM58Meta?.alias || [],
    redirect: minejPal5ljM58Meta?.redirect,
    component: () => import("/vercel/path0/pages/design/mine.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bg85IcmEx3Meta?.name ?? "editor-slug___en-au",
    path: _91slug_93bg85IcmEx3Meta?.path ?? "/editor/:slug()",
    meta: _91slug_93bg85IcmEx3Meta || {},
    alias: _91slug_93bg85IcmEx3Meta?.alias || [],
    redirect: _91slug_93bg85IcmEx3Meta?.redirect,
    component: () => import("/vercel/path0/pages/editor/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bg85IcmEx3Meta?.name ?? "editor-slug___en-us",
    path: _91slug_93bg85IcmEx3Meta?.path ?? "/en-us/editor/:slug()",
    meta: _91slug_93bg85IcmEx3Meta || {},
    alias: _91slug_93bg85IcmEx3Meta?.alias || [],
    redirect: _91slug_93bg85IcmEx3Meta?.redirect,
    component: () => import("/vercel/path0/pages/editor/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bg85IcmEx3Meta?.name ?? "editor-slug___de",
    path: _91slug_93bg85IcmEx3Meta?.path ?? "/de/editor/:slug()",
    meta: _91slug_93bg85IcmEx3Meta || {},
    alias: _91slug_93bg85IcmEx3Meta?.alias || [],
    redirect: _91slug_93bg85IcmEx3Meta?.redirect,
    component: () => import("/vercel/path0/pages/editor/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bg85IcmEx3Meta?.name ?? "editor-slug___fr",
    path: _91slug_93bg85IcmEx3Meta?.path ?? "/fr/editor/:slug()",
    meta: _91slug_93bg85IcmEx3Meta || {},
    alias: _91slug_93bg85IcmEx3Meta?.alias || [],
    redirect: _91slug_93bg85IcmEx3Meta?.redirect,
    component: () => import("/vercel/path0/pages/editor/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bg85IcmEx3Meta?.name ?? "editor-slug___ja",
    path: _91slug_93bg85IcmEx3Meta?.path ?? "/ja/editor/:slug()",
    meta: _91slug_93bg85IcmEx3Meta || {},
    alias: _91slug_93bg85IcmEx3Meta?.alias || [],
    redirect: _91slug_93bg85IcmEx3Meta?.redirect,
    component: () => import("/vercel/path0/pages/editor/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bg85IcmEx3Meta?.name ?? "editor-slug___es",
    path: _91slug_93bg85IcmEx3Meta?.path ?? "/es/editor/:slug()",
    meta: _91slug_93bg85IcmEx3Meta || {},
    alias: _91slug_93bg85IcmEx3Meta?.alias || [],
    redirect: _91slug_93bg85IcmEx3Meta?.redirect,
    component: () => import("/vercel/path0/pages/editor/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91key_93G6Xn0CB9gSMeta?.name ?? "embed-key___en-au",
    path: _91key_93G6Xn0CB9gSMeta?.path ?? "/embed/:key()",
    meta: _91key_93G6Xn0CB9gSMeta || {},
    alias: _91key_93G6Xn0CB9gSMeta?.alias || [],
    redirect: _91key_93G6Xn0CB9gSMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93G6Xn0CB9gSMeta?.name ?? "embed-key___en-us",
    path: _91key_93G6Xn0CB9gSMeta?.path ?? "/en-us/embed/:key()",
    meta: _91key_93G6Xn0CB9gSMeta || {},
    alias: _91key_93G6Xn0CB9gSMeta?.alias || [],
    redirect: _91key_93G6Xn0CB9gSMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93G6Xn0CB9gSMeta?.name ?? "embed-key___de",
    path: _91key_93G6Xn0CB9gSMeta?.path ?? "/de/embed/:key()",
    meta: _91key_93G6Xn0CB9gSMeta || {},
    alias: _91key_93G6Xn0CB9gSMeta?.alias || [],
    redirect: _91key_93G6Xn0CB9gSMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93G6Xn0CB9gSMeta?.name ?? "embed-key___fr",
    path: _91key_93G6Xn0CB9gSMeta?.path ?? "/fr/embed/:key()",
    meta: _91key_93G6Xn0CB9gSMeta || {},
    alias: _91key_93G6Xn0CB9gSMeta?.alias || [],
    redirect: _91key_93G6Xn0CB9gSMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93G6Xn0CB9gSMeta?.name ?? "embed-key___ja",
    path: _91key_93G6Xn0CB9gSMeta?.path ?? "/ja/embed/:key()",
    meta: _91key_93G6Xn0CB9gSMeta || {},
    alias: _91key_93G6Xn0CB9gSMeta?.alias || [],
    redirect: _91key_93G6Xn0CB9gSMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93G6Xn0CB9gSMeta?.name ?? "embed-key___es",
    path: _91key_93G6Xn0CB9gSMeta?.path ?? "/es/embed/:key()",
    meta: _91key_93G6Xn0CB9gSMeta || {},
    alias: _91key_93G6Xn0CB9gSMeta?.alias || [],
    redirect: _91key_93G6Xn0CB9gSMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed/[key].vue").then(m => m.default || m)
  },
  {
    name: testSPr3lHQMFpMeta?.name ?? "embed-test___en-au",
    path: testSPr3lHQMFpMeta?.path ?? "/embed/test",
    meta: testSPr3lHQMFpMeta || {},
    alias: testSPr3lHQMFpMeta?.alias || [],
    redirect: testSPr3lHQMFpMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed/test.vue").then(m => m.default || m)
  },
  {
    name: testSPr3lHQMFpMeta?.name ?? "embed-test___en-us",
    path: testSPr3lHQMFpMeta?.path ?? "/en-us/embed/test",
    meta: testSPr3lHQMFpMeta || {},
    alias: testSPr3lHQMFpMeta?.alias || [],
    redirect: testSPr3lHQMFpMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed/test.vue").then(m => m.default || m)
  },
  {
    name: testSPr3lHQMFpMeta?.name ?? "embed-test___de",
    path: testSPr3lHQMFpMeta?.path ?? "/de/embed/test",
    meta: testSPr3lHQMFpMeta || {},
    alias: testSPr3lHQMFpMeta?.alias || [],
    redirect: testSPr3lHQMFpMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed/test.vue").then(m => m.default || m)
  },
  {
    name: testSPr3lHQMFpMeta?.name ?? "embed-test___fr",
    path: testSPr3lHQMFpMeta?.path ?? "/fr/embed/test",
    meta: testSPr3lHQMFpMeta || {},
    alias: testSPr3lHQMFpMeta?.alias || [],
    redirect: testSPr3lHQMFpMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed/test.vue").then(m => m.default || m)
  },
  {
    name: testSPr3lHQMFpMeta?.name ?? "embed-test___ja",
    path: testSPr3lHQMFpMeta?.path ?? "/ja/embed/test",
    meta: testSPr3lHQMFpMeta || {},
    alias: testSPr3lHQMFpMeta?.alias || [],
    redirect: testSPr3lHQMFpMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed/test.vue").then(m => m.default || m)
  },
  {
    name: testSPr3lHQMFpMeta?.name ?? "embed-test___es",
    path: testSPr3lHQMFpMeta?.path ?? "/es/embed/test",
    meta: testSPr3lHQMFpMeta || {},
    alias: testSPr3lHQMFpMeta?.alias || [],
    redirect: testSPr3lHQMFpMeta?.redirect,
    component: () => import("/vercel/path0/pages/embed/test.vue").then(m => m.default || m)
  },
  {
    name: _91id_93EBw3e5WRXxMeta?.name ?? "episodes-id___en-au",
    path: _91id_93EBw3e5WRXxMeta?.path ?? "/episodes/:id()",
    meta: _91id_93EBw3e5WRXxMeta || {},
    alias: _91id_93EBw3e5WRXxMeta?.alias || [],
    redirect: _91id_93EBw3e5WRXxMeta?.redirect,
    component: () => import("/vercel/path0/pages/episodes/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EBw3e5WRXxMeta?.name ?? "episodes-id___en-us",
    path: _91id_93EBw3e5WRXxMeta?.path ?? "/en-us/episodes/:id()",
    meta: _91id_93EBw3e5WRXxMeta || {},
    alias: _91id_93EBw3e5WRXxMeta?.alias || [],
    redirect: _91id_93EBw3e5WRXxMeta?.redirect,
    component: () => import("/vercel/path0/pages/episodes/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EBw3e5WRXxMeta?.name ?? "episodes-id___de",
    path: _91id_93EBw3e5WRXxMeta?.path ?? "/de/episodes/:id()",
    meta: _91id_93EBw3e5WRXxMeta || {},
    alias: _91id_93EBw3e5WRXxMeta?.alias || [],
    redirect: _91id_93EBw3e5WRXxMeta?.redirect,
    component: () => import("/vercel/path0/pages/episodes/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EBw3e5WRXxMeta?.name ?? "episodes-id___fr",
    path: _91id_93EBw3e5WRXxMeta?.path ?? "/fr/episodes/:id()",
    meta: _91id_93EBw3e5WRXxMeta || {},
    alias: _91id_93EBw3e5WRXxMeta?.alias || [],
    redirect: _91id_93EBw3e5WRXxMeta?.redirect,
    component: () => import("/vercel/path0/pages/episodes/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EBw3e5WRXxMeta?.name ?? "episodes-id___ja",
    path: _91id_93EBw3e5WRXxMeta?.path ?? "/ja/episodes/:id()",
    meta: _91id_93EBw3e5WRXxMeta || {},
    alias: _91id_93EBw3e5WRXxMeta?.alias || [],
    redirect: _91id_93EBw3e5WRXxMeta?.redirect,
    component: () => import("/vercel/path0/pages/episodes/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EBw3e5WRXxMeta?.name ?? "episodes-id___es",
    path: _91id_93EBw3e5WRXxMeta?.path ?? "/es/episodes/:id()",
    meta: _91id_93EBw3e5WRXxMeta || {},
    alias: _91id_93EBw3e5WRXxMeta?.alias || [],
    redirect: _91id_93EBw3e5WRXxMeta?.redirect,
    component: () => import("/vercel/path0/pages/episodes/[id].vue").then(m => m.default || m)
  },
  {
    name: faqsbR89DgHZwlMeta?.name ?? "faqs___en-au",
    path: faqsbR89DgHZwlMeta?.path ?? "/faqs",
    meta: faqsbR89DgHZwlMeta || {},
    alias: faqsbR89DgHZwlMeta?.alias || [],
    redirect: faqsbR89DgHZwlMeta?.redirect,
    component: () => import("/vercel/path0/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: faqsbR89DgHZwlMeta?.name ?? "faqs___en-us",
    path: faqsbR89DgHZwlMeta?.path ?? "/en-us/faqs",
    meta: faqsbR89DgHZwlMeta || {},
    alias: faqsbR89DgHZwlMeta?.alias || [],
    redirect: faqsbR89DgHZwlMeta?.redirect,
    component: () => import("/vercel/path0/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: faqsbR89DgHZwlMeta?.name ?? "faqs___de",
    path: faqsbR89DgHZwlMeta?.path ?? "/de/faqs",
    meta: faqsbR89DgHZwlMeta || {},
    alias: faqsbR89DgHZwlMeta?.alias || [],
    redirect: faqsbR89DgHZwlMeta?.redirect,
    component: () => import("/vercel/path0/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: faqsbR89DgHZwlMeta?.name ?? "faqs___fr",
    path: faqsbR89DgHZwlMeta?.path ?? "/fr/faqs",
    meta: faqsbR89DgHZwlMeta || {},
    alias: faqsbR89DgHZwlMeta?.alias || [],
    redirect: faqsbR89DgHZwlMeta?.redirect,
    component: () => import("/vercel/path0/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: faqsbR89DgHZwlMeta?.name ?? "faqs___ja",
    path: faqsbR89DgHZwlMeta?.path ?? "/ja/faqs",
    meta: faqsbR89DgHZwlMeta || {},
    alias: faqsbR89DgHZwlMeta?.alias || [],
    redirect: faqsbR89DgHZwlMeta?.redirect,
    component: () => import("/vercel/path0/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: faqsbR89DgHZwlMeta?.name ?? "faqs___es",
    path: faqsbR89DgHZwlMeta?.path ?? "/es/faqs",
    meta: faqsbR89DgHZwlMeta || {},
    alias: faqsbR89DgHZwlMeta?.alias || [],
    redirect: faqsbR89DgHZwlMeta?.redirect,
    component: () => import("/vercel/path0/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: free_45recorder_45toolURHgpeUHfXMeta?.name ?? "free-recorder-tool___en-au",
    path: free_45recorder_45toolURHgpeUHfXMeta?.path ?? "/free-recorder-tool",
    meta: free_45recorder_45toolURHgpeUHfXMeta || {},
    alias: free_45recorder_45toolURHgpeUHfXMeta?.alias || [],
    redirect: free_45recorder_45toolURHgpeUHfXMeta?.redirect,
    component: () => import("/vercel/path0/pages/free-recorder-tool.vue").then(m => m.default || m)
  },
  {
    name: free_45recorder_45toolURHgpeUHfXMeta?.name ?? "free-recorder-tool___en-us",
    path: free_45recorder_45toolURHgpeUHfXMeta?.path ?? "/en-us/free-recorder-tool",
    meta: free_45recorder_45toolURHgpeUHfXMeta || {},
    alias: free_45recorder_45toolURHgpeUHfXMeta?.alias || [],
    redirect: free_45recorder_45toolURHgpeUHfXMeta?.redirect,
    component: () => import("/vercel/path0/pages/free-recorder-tool.vue").then(m => m.default || m)
  },
  {
    name: free_45recorder_45toolURHgpeUHfXMeta?.name ?? "free-recorder-tool___de",
    path: free_45recorder_45toolURHgpeUHfXMeta?.path ?? "/de/free-recorder-tool",
    meta: free_45recorder_45toolURHgpeUHfXMeta || {},
    alias: free_45recorder_45toolURHgpeUHfXMeta?.alias || [],
    redirect: free_45recorder_45toolURHgpeUHfXMeta?.redirect,
    component: () => import("/vercel/path0/pages/free-recorder-tool.vue").then(m => m.default || m)
  },
  {
    name: free_45recorder_45toolURHgpeUHfXMeta?.name ?? "free-recorder-tool___fr",
    path: free_45recorder_45toolURHgpeUHfXMeta?.path ?? "/fr/free-recorder-tool",
    meta: free_45recorder_45toolURHgpeUHfXMeta || {},
    alias: free_45recorder_45toolURHgpeUHfXMeta?.alias || [],
    redirect: free_45recorder_45toolURHgpeUHfXMeta?.redirect,
    component: () => import("/vercel/path0/pages/free-recorder-tool.vue").then(m => m.default || m)
  },
  {
    name: free_45recorder_45toolURHgpeUHfXMeta?.name ?? "free-recorder-tool___ja",
    path: free_45recorder_45toolURHgpeUHfXMeta?.path ?? "/ja/free-recorder-tool",
    meta: free_45recorder_45toolURHgpeUHfXMeta || {},
    alias: free_45recorder_45toolURHgpeUHfXMeta?.alias || [],
    redirect: free_45recorder_45toolURHgpeUHfXMeta?.redirect,
    component: () => import("/vercel/path0/pages/free-recorder-tool.vue").then(m => m.default || m)
  },
  {
    name: free_45recorder_45toolURHgpeUHfXMeta?.name ?? "free-recorder-tool___es",
    path: free_45recorder_45toolURHgpeUHfXMeta?.path ?? "/es/free-recorder-tool",
    meta: free_45recorder_45toolURHgpeUHfXMeta || {},
    alias: free_45recorder_45toolURHgpeUHfXMeta?.alias || [],
    redirect: free_45recorder_45toolURHgpeUHfXMeta?.redirect,
    component: () => import("/vercel/path0/pages/free-recorder-tool.vue").then(m => m.default || m)
  },
  {
    name: free_45tools38ZvgRTc85Meta?.name ?? "free-tools___en-au",
    path: free_45tools38ZvgRTc85Meta?.path ?? "/free-tools",
    meta: free_45tools38ZvgRTc85Meta || {},
    alias: free_45tools38ZvgRTc85Meta?.alias || [],
    redirect: free_45tools38ZvgRTc85Meta?.redirect,
    component: () => import("/vercel/path0/pages/free-tools.vue").then(m => m.default || m)
  },
  {
    name: free_45tools38ZvgRTc85Meta?.name ?? "free-tools___en-us",
    path: free_45tools38ZvgRTc85Meta?.path ?? "/en-us/free-tools",
    meta: free_45tools38ZvgRTc85Meta || {},
    alias: free_45tools38ZvgRTc85Meta?.alias || [],
    redirect: free_45tools38ZvgRTc85Meta?.redirect,
    component: () => import("/vercel/path0/pages/free-tools.vue").then(m => m.default || m)
  },
  {
    name: free_45tools38ZvgRTc85Meta?.name ?? "free-tools___de",
    path: free_45tools38ZvgRTc85Meta?.path ?? "/de/free-tools",
    meta: free_45tools38ZvgRTc85Meta || {},
    alias: free_45tools38ZvgRTc85Meta?.alias || [],
    redirect: free_45tools38ZvgRTc85Meta?.redirect,
    component: () => import("/vercel/path0/pages/free-tools.vue").then(m => m.default || m)
  },
  {
    name: free_45tools38ZvgRTc85Meta?.name ?? "free-tools___fr",
    path: free_45tools38ZvgRTc85Meta?.path ?? "/fr/free-tools",
    meta: free_45tools38ZvgRTc85Meta || {},
    alias: free_45tools38ZvgRTc85Meta?.alias || [],
    redirect: free_45tools38ZvgRTc85Meta?.redirect,
    component: () => import("/vercel/path0/pages/free-tools.vue").then(m => m.default || m)
  },
  {
    name: free_45tools38ZvgRTc85Meta?.name ?? "free-tools___ja",
    path: free_45tools38ZvgRTc85Meta?.path ?? "/ja/free-tools",
    meta: free_45tools38ZvgRTc85Meta || {},
    alias: free_45tools38ZvgRTc85Meta?.alias || [],
    redirect: free_45tools38ZvgRTc85Meta?.redirect,
    component: () => import("/vercel/path0/pages/free-tools.vue").then(m => m.default || m)
  },
  {
    name: free_45tools38ZvgRTc85Meta?.name ?? "free-tools___es",
    path: free_45tools38ZvgRTc85Meta?.path ?? "/es/free-tools",
    meta: free_45tools38ZvgRTc85Meta || {},
    alias: free_45tools38ZvgRTc85Meta?.alias || [],
    redirect: free_45tools38ZvgRTc85Meta?.redirect,
    component: () => import("/vercel/path0/pages/free-tools.vue").then(m => m.default || m)
  },
  {
    name: free_45transcription_45toolkdNUaQBrX6Meta?.name ?? "free-transcription-tool___en-au",
    path: free_45transcription_45toolkdNUaQBrX6Meta?.path ?? "/free-transcription-tool",
    meta: free_45transcription_45toolkdNUaQBrX6Meta || {},
    alias: free_45transcription_45toolkdNUaQBrX6Meta?.alias || [],
    redirect: free_45transcription_45toolkdNUaQBrX6Meta?.redirect,
    component: () => import("/vercel/path0/pages/free-transcription-tool.vue").then(m => m.default || m)
  },
  {
    name: free_45transcription_45toolkdNUaQBrX6Meta?.name ?? "free-transcription-tool___en-us",
    path: free_45transcription_45toolkdNUaQBrX6Meta?.path ?? "/en-us/free-transcription-tool",
    meta: free_45transcription_45toolkdNUaQBrX6Meta || {},
    alias: free_45transcription_45toolkdNUaQBrX6Meta?.alias || [],
    redirect: free_45transcription_45toolkdNUaQBrX6Meta?.redirect,
    component: () => import("/vercel/path0/pages/free-transcription-tool.vue").then(m => m.default || m)
  },
  {
    name: free_45transcription_45toolkdNUaQBrX6Meta?.name ?? "free-transcription-tool___de",
    path: free_45transcription_45toolkdNUaQBrX6Meta?.path ?? "/de/free-transcription-tool",
    meta: free_45transcription_45toolkdNUaQBrX6Meta || {},
    alias: free_45transcription_45toolkdNUaQBrX6Meta?.alias || [],
    redirect: free_45transcription_45toolkdNUaQBrX6Meta?.redirect,
    component: () => import("/vercel/path0/pages/free-transcription-tool.vue").then(m => m.default || m)
  },
  {
    name: free_45transcription_45toolkdNUaQBrX6Meta?.name ?? "free-transcription-tool___fr",
    path: free_45transcription_45toolkdNUaQBrX6Meta?.path ?? "/fr/free-transcription-tool",
    meta: free_45transcription_45toolkdNUaQBrX6Meta || {},
    alias: free_45transcription_45toolkdNUaQBrX6Meta?.alias || [],
    redirect: free_45transcription_45toolkdNUaQBrX6Meta?.redirect,
    component: () => import("/vercel/path0/pages/free-transcription-tool.vue").then(m => m.default || m)
  },
  {
    name: free_45transcription_45toolkdNUaQBrX6Meta?.name ?? "free-transcription-tool___ja",
    path: free_45transcription_45toolkdNUaQBrX6Meta?.path ?? "/ja/free-transcription-tool",
    meta: free_45transcription_45toolkdNUaQBrX6Meta || {},
    alias: free_45transcription_45toolkdNUaQBrX6Meta?.alias || [],
    redirect: free_45transcription_45toolkdNUaQBrX6Meta?.redirect,
    component: () => import("/vercel/path0/pages/free-transcription-tool.vue").then(m => m.default || m)
  },
  {
    name: free_45transcription_45toolkdNUaQBrX6Meta?.name ?? "free-transcription-tool___es",
    path: free_45transcription_45toolkdNUaQBrX6Meta?.path ?? "/es/free-transcription-tool",
    meta: free_45transcription_45toolkdNUaQBrX6Meta || {},
    alias: free_45transcription_45toolkdNUaQBrX6Meta?.alias || [],
    redirect: free_45transcription_45toolkdNUaQBrX6Meta?.redirect,
    component: () => import("/vercel/path0/pages/free-transcription-tool.vue").then(m => m.default || m)
  },
  {
    name: gradientsiCF6RJEtlvMeta?.name ?? "gradients___en-au",
    path: gradientsiCF6RJEtlvMeta?.path ?? "/gradients",
    meta: gradientsiCF6RJEtlvMeta || {},
    alias: gradientsiCF6RJEtlvMeta?.alias || [],
    redirect: gradientsiCF6RJEtlvMeta?.redirect,
    component: () => import("/vercel/path0/pages/gradients.vue").then(m => m.default || m)
  },
  {
    name: gradientsiCF6RJEtlvMeta?.name ?? "gradients___en-us",
    path: gradientsiCF6RJEtlvMeta?.path ?? "/en-us/gradients",
    meta: gradientsiCF6RJEtlvMeta || {},
    alias: gradientsiCF6RJEtlvMeta?.alias || [],
    redirect: gradientsiCF6RJEtlvMeta?.redirect,
    component: () => import("/vercel/path0/pages/gradients.vue").then(m => m.default || m)
  },
  {
    name: gradientsiCF6RJEtlvMeta?.name ?? "gradients___de",
    path: gradientsiCF6RJEtlvMeta?.path ?? "/de/gradients",
    meta: gradientsiCF6RJEtlvMeta || {},
    alias: gradientsiCF6RJEtlvMeta?.alias || [],
    redirect: gradientsiCF6RJEtlvMeta?.redirect,
    component: () => import("/vercel/path0/pages/gradients.vue").then(m => m.default || m)
  },
  {
    name: gradientsiCF6RJEtlvMeta?.name ?? "gradients___fr",
    path: gradientsiCF6RJEtlvMeta?.path ?? "/fr/gradients",
    meta: gradientsiCF6RJEtlvMeta || {},
    alias: gradientsiCF6RJEtlvMeta?.alias || [],
    redirect: gradientsiCF6RJEtlvMeta?.redirect,
    component: () => import("/vercel/path0/pages/gradients.vue").then(m => m.default || m)
  },
  {
    name: gradientsiCF6RJEtlvMeta?.name ?? "gradients___ja",
    path: gradientsiCF6RJEtlvMeta?.path ?? "/ja/gradients",
    meta: gradientsiCF6RJEtlvMeta || {},
    alias: gradientsiCF6RJEtlvMeta?.alias || [],
    redirect: gradientsiCF6RJEtlvMeta?.redirect,
    component: () => import("/vercel/path0/pages/gradients.vue").then(m => m.default || m)
  },
  {
    name: gradientsiCF6RJEtlvMeta?.name ?? "gradients___es",
    path: gradientsiCF6RJEtlvMeta?.path ?? "/es/gradients",
    meta: gradientsiCF6RJEtlvMeta || {},
    alias: gradientsiCF6RJEtlvMeta?.alias || [],
    redirect: gradientsiCF6RJEtlvMeta?.redirect,
    component: () => import("/vercel/path0/pages/gradients.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en-au",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en-us",
    path: indexe9Brt5DfdhMeta?.path ?? "/en-us",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___de",
    path: indexe9Brt5DfdhMeta?.path ?? "/de",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___fr",
    path: indexe9Brt5DfdhMeta?.path ?? "/fr",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___ja",
    path: indexe9Brt5DfdhMeta?.path ?? "/ja",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___es",
    path: indexe9Brt5DfdhMeta?.path ?? "/es",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: onboardingewPMQ0QeckMeta?.name ?? "onboarding___en-au",
    path: onboardingewPMQ0QeckMeta?.path ?? "/onboarding",
    meta: onboardingewPMQ0QeckMeta || {},
    alias: onboardingewPMQ0QeckMeta?.alias || [],
    redirect: onboardingewPMQ0QeckMeta?.redirect,
    component: () => import("/vercel/path0/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: onboardingewPMQ0QeckMeta?.name ?? "onboarding___en-us",
    path: onboardingewPMQ0QeckMeta?.path ?? "/en-us/onboarding",
    meta: onboardingewPMQ0QeckMeta || {},
    alias: onboardingewPMQ0QeckMeta?.alias || [],
    redirect: onboardingewPMQ0QeckMeta?.redirect,
    component: () => import("/vercel/path0/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: onboardingewPMQ0QeckMeta?.name ?? "onboarding___de",
    path: onboardingewPMQ0QeckMeta?.path ?? "/de/onboarding",
    meta: onboardingewPMQ0QeckMeta || {},
    alias: onboardingewPMQ0QeckMeta?.alias || [],
    redirect: onboardingewPMQ0QeckMeta?.redirect,
    component: () => import("/vercel/path0/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: onboardingewPMQ0QeckMeta?.name ?? "onboarding___fr",
    path: onboardingewPMQ0QeckMeta?.path ?? "/fr/onboarding",
    meta: onboardingewPMQ0QeckMeta || {},
    alias: onboardingewPMQ0QeckMeta?.alias || [],
    redirect: onboardingewPMQ0QeckMeta?.redirect,
    component: () => import("/vercel/path0/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: onboardingewPMQ0QeckMeta?.name ?? "onboarding___ja",
    path: onboardingewPMQ0QeckMeta?.path ?? "/ja/onboarding",
    meta: onboardingewPMQ0QeckMeta || {},
    alias: onboardingewPMQ0QeckMeta?.alias || [],
    redirect: onboardingewPMQ0QeckMeta?.redirect,
    component: () => import("/vercel/path0/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: onboardingewPMQ0QeckMeta?.name ?? "onboarding___es",
    path: onboardingewPMQ0QeckMeta?.path ?? "/es/onboarding",
    meta: onboardingewPMQ0QeckMeta || {},
    alias: onboardingewPMQ0QeckMeta?.alias || [],
    redirect: onboardingewPMQ0QeckMeta?.redirect,
    component: () => import("/vercel/path0/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Y1SABpq4rEMeta?.name ?? "password-reset-confirm-uid-token___en-au",
    path: _91token_93Y1SABpq4rEMeta?.path ?? "/password-reset/confirm/:uid()/:token()",
    meta: _91token_93Y1SABpq4rEMeta || {},
    alias: _91token_93Y1SABpq4rEMeta?.alias || [],
    redirect: _91token_93Y1SABpq4rEMeta?.redirect,
    component: () => import("/vercel/path0/pages/password-reset/confirm/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Y1SABpq4rEMeta?.name ?? "password-reset-confirm-uid-token___en-us",
    path: _91token_93Y1SABpq4rEMeta?.path ?? "/en-us/password-reset/confirm/:uid()/:token()",
    meta: _91token_93Y1SABpq4rEMeta || {},
    alias: _91token_93Y1SABpq4rEMeta?.alias || [],
    redirect: _91token_93Y1SABpq4rEMeta?.redirect,
    component: () => import("/vercel/path0/pages/password-reset/confirm/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Y1SABpq4rEMeta?.name ?? "password-reset-confirm-uid-token___de",
    path: _91token_93Y1SABpq4rEMeta?.path ?? "/de/password-reset/confirm/:uid()/:token()",
    meta: _91token_93Y1SABpq4rEMeta || {},
    alias: _91token_93Y1SABpq4rEMeta?.alias || [],
    redirect: _91token_93Y1SABpq4rEMeta?.redirect,
    component: () => import("/vercel/path0/pages/password-reset/confirm/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Y1SABpq4rEMeta?.name ?? "password-reset-confirm-uid-token___fr",
    path: _91token_93Y1SABpq4rEMeta?.path ?? "/fr/password-reset/confirm/:uid()/:token()",
    meta: _91token_93Y1SABpq4rEMeta || {},
    alias: _91token_93Y1SABpq4rEMeta?.alias || [],
    redirect: _91token_93Y1SABpq4rEMeta?.redirect,
    component: () => import("/vercel/path0/pages/password-reset/confirm/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Y1SABpq4rEMeta?.name ?? "password-reset-confirm-uid-token___ja",
    path: _91token_93Y1SABpq4rEMeta?.path ?? "/ja/password-reset/confirm/:uid()/:token()",
    meta: _91token_93Y1SABpq4rEMeta || {},
    alias: _91token_93Y1SABpq4rEMeta?.alias || [],
    redirect: _91token_93Y1SABpq4rEMeta?.redirect,
    component: () => import("/vercel/path0/pages/password-reset/confirm/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Y1SABpq4rEMeta?.name ?? "password-reset-confirm-uid-token___es",
    path: _91token_93Y1SABpq4rEMeta?.path ?? "/es/password-reset/confirm/:uid()/:token()",
    meta: _91token_93Y1SABpq4rEMeta || {},
    alias: _91token_93Y1SABpq4rEMeta?.alias || [],
    redirect: _91token_93Y1SABpq4rEMeta?.redirect,
    component: () => import("/vercel/path0/pages/password-reset/confirm/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: payment_45cancelPYhEBW8BJIMeta?.name ?? "payment-cancel___en-au",
    path: payment_45cancelPYhEBW8BJIMeta?.path ?? "/payment-cancel",
    meta: payment_45cancelPYhEBW8BJIMeta || {},
    alias: payment_45cancelPYhEBW8BJIMeta?.alias || [],
    redirect: payment_45cancelPYhEBW8BJIMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment-cancel.vue").then(m => m.default || m)
  },
  {
    name: payment_45cancelPYhEBW8BJIMeta?.name ?? "payment-cancel___en-us",
    path: payment_45cancelPYhEBW8BJIMeta?.path ?? "/en-us/payment-cancel",
    meta: payment_45cancelPYhEBW8BJIMeta || {},
    alias: payment_45cancelPYhEBW8BJIMeta?.alias || [],
    redirect: payment_45cancelPYhEBW8BJIMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment-cancel.vue").then(m => m.default || m)
  },
  {
    name: payment_45cancelPYhEBW8BJIMeta?.name ?? "payment-cancel___de",
    path: payment_45cancelPYhEBW8BJIMeta?.path ?? "/de/payment-cancel",
    meta: payment_45cancelPYhEBW8BJIMeta || {},
    alias: payment_45cancelPYhEBW8BJIMeta?.alias || [],
    redirect: payment_45cancelPYhEBW8BJIMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment-cancel.vue").then(m => m.default || m)
  },
  {
    name: payment_45cancelPYhEBW8BJIMeta?.name ?? "payment-cancel___fr",
    path: payment_45cancelPYhEBW8BJIMeta?.path ?? "/fr/payment-cancel",
    meta: payment_45cancelPYhEBW8BJIMeta || {},
    alias: payment_45cancelPYhEBW8BJIMeta?.alias || [],
    redirect: payment_45cancelPYhEBW8BJIMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment-cancel.vue").then(m => m.default || m)
  },
  {
    name: payment_45cancelPYhEBW8BJIMeta?.name ?? "payment-cancel___ja",
    path: payment_45cancelPYhEBW8BJIMeta?.path ?? "/ja/payment-cancel",
    meta: payment_45cancelPYhEBW8BJIMeta || {},
    alias: payment_45cancelPYhEBW8BJIMeta?.alias || [],
    redirect: payment_45cancelPYhEBW8BJIMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment-cancel.vue").then(m => m.default || m)
  },
  {
    name: payment_45cancelPYhEBW8BJIMeta?.name ?? "payment-cancel___es",
    path: payment_45cancelPYhEBW8BJIMeta?.path ?? "/es/payment-cancel",
    meta: payment_45cancelPYhEBW8BJIMeta || {},
    alias: payment_45cancelPYhEBW8BJIMeta?.alias || [],
    redirect: payment_45cancelPYhEBW8BJIMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment-cancel.vue").then(m => m.default || m)
  },
  {
    name: payment_45successAGfXBekK7fMeta?.name ?? "payment-success___en-au",
    path: payment_45successAGfXBekK7fMeta?.path ?? "/payment-success",
    meta: payment_45successAGfXBekK7fMeta || {},
    alias: payment_45successAGfXBekK7fMeta?.alias || [],
    redirect: payment_45successAGfXBekK7fMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment-success.vue").then(m => m.default || m)
  },
  {
    name: payment_45successAGfXBekK7fMeta?.name ?? "payment-success___en-us",
    path: payment_45successAGfXBekK7fMeta?.path ?? "/en-us/payment-success",
    meta: payment_45successAGfXBekK7fMeta || {},
    alias: payment_45successAGfXBekK7fMeta?.alias || [],
    redirect: payment_45successAGfXBekK7fMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment-success.vue").then(m => m.default || m)
  },
  {
    name: payment_45successAGfXBekK7fMeta?.name ?? "payment-success___de",
    path: payment_45successAGfXBekK7fMeta?.path ?? "/de/payment-success",
    meta: payment_45successAGfXBekK7fMeta || {},
    alias: payment_45successAGfXBekK7fMeta?.alias || [],
    redirect: payment_45successAGfXBekK7fMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment-success.vue").then(m => m.default || m)
  },
  {
    name: payment_45successAGfXBekK7fMeta?.name ?? "payment-success___fr",
    path: payment_45successAGfXBekK7fMeta?.path ?? "/fr/payment-success",
    meta: payment_45successAGfXBekK7fMeta || {},
    alias: payment_45successAGfXBekK7fMeta?.alias || [],
    redirect: payment_45successAGfXBekK7fMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment-success.vue").then(m => m.default || m)
  },
  {
    name: payment_45successAGfXBekK7fMeta?.name ?? "payment-success___ja",
    path: payment_45successAGfXBekK7fMeta?.path ?? "/ja/payment-success",
    meta: payment_45successAGfXBekK7fMeta || {},
    alias: payment_45successAGfXBekK7fMeta?.alias || [],
    redirect: payment_45successAGfXBekK7fMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment-success.vue").then(m => m.default || m)
  },
  {
    name: payment_45successAGfXBekK7fMeta?.name ?? "payment-success___es",
    path: payment_45successAGfXBekK7fMeta?.path ?? "/es/payment-success",
    meta: payment_45successAGfXBekK7fMeta || {},
    alias: payment_45successAGfXBekK7fMeta?.alias || [],
    redirect: payment_45successAGfXBekK7fMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment-success.vue").then(m => m.default || m)
  },
  {
    name: podcastspFORAEWMrfMeta?.name ?? "podcasts___en-au",
    path: podcastspFORAEWMrfMeta?.path ?? "/podcasts",
    meta: podcastspFORAEWMrfMeta || {},
    alias: podcastspFORAEWMrfMeta?.alias || [],
    redirect: podcastspFORAEWMrfMeta?.redirect,
    component: () => import("/vercel/path0/pages/podcasts.vue").then(m => m.default || m)
  },
  {
    name: podcastspFORAEWMrfMeta?.name ?? "podcasts___en-us",
    path: podcastspFORAEWMrfMeta?.path ?? "/en-us/podcasts",
    meta: podcastspFORAEWMrfMeta || {},
    alias: podcastspFORAEWMrfMeta?.alias || [],
    redirect: podcastspFORAEWMrfMeta?.redirect,
    component: () => import("/vercel/path0/pages/podcasts.vue").then(m => m.default || m)
  },
  {
    name: podcastspFORAEWMrfMeta?.name ?? "podcasts___de",
    path: podcastspFORAEWMrfMeta?.path ?? "/de/podcasts",
    meta: podcastspFORAEWMrfMeta || {},
    alias: podcastspFORAEWMrfMeta?.alias || [],
    redirect: podcastspFORAEWMrfMeta?.redirect,
    component: () => import("/vercel/path0/pages/podcasts.vue").then(m => m.default || m)
  },
  {
    name: podcastspFORAEWMrfMeta?.name ?? "podcasts___fr",
    path: podcastspFORAEWMrfMeta?.path ?? "/fr/podcasts",
    meta: podcastspFORAEWMrfMeta || {},
    alias: podcastspFORAEWMrfMeta?.alias || [],
    redirect: podcastspFORAEWMrfMeta?.redirect,
    component: () => import("/vercel/path0/pages/podcasts.vue").then(m => m.default || m)
  },
  {
    name: podcastspFORAEWMrfMeta?.name ?? "podcasts___ja",
    path: podcastspFORAEWMrfMeta?.path ?? "/ja/podcasts",
    meta: podcastspFORAEWMrfMeta || {},
    alias: podcastspFORAEWMrfMeta?.alias || [],
    redirect: podcastspFORAEWMrfMeta?.redirect,
    component: () => import("/vercel/path0/pages/podcasts.vue").then(m => m.default || m)
  },
  {
    name: podcastspFORAEWMrfMeta?.name ?? "podcasts___es",
    path: podcastspFORAEWMrfMeta?.path ?? "/es/podcasts",
    meta: podcastspFORAEWMrfMeta || {},
    alias: podcastspFORAEWMrfMeta?.alias || [],
    redirect: podcastspFORAEWMrfMeta?.redirect,
    component: () => import("/vercel/path0/pages/podcasts.vue").then(m => m.default || m)
  },
  {
    name: pricingIYNdvfoSO6Meta?.name ?? "pricing___en-au",
    path: pricingIYNdvfoSO6Meta?.path ?? "/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    alias: pricingIYNdvfoSO6Meta?.alias || [],
    redirect: pricingIYNdvfoSO6Meta?.redirect,
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingIYNdvfoSO6Meta?.name ?? "pricing___en-us",
    path: pricingIYNdvfoSO6Meta?.path ?? "/en-us/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    alias: pricingIYNdvfoSO6Meta?.alias || [],
    redirect: pricingIYNdvfoSO6Meta?.redirect,
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingIYNdvfoSO6Meta?.name ?? "pricing___de",
    path: pricingIYNdvfoSO6Meta?.path ?? "/de/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    alias: pricingIYNdvfoSO6Meta?.alias || [],
    redirect: pricingIYNdvfoSO6Meta?.redirect,
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingIYNdvfoSO6Meta?.name ?? "pricing___fr",
    path: pricingIYNdvfoSO6Meta?.path ?? "/fr/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    alias: pricingIYNdvfoSO6Meta?.alias || [],
    redirect: pricingIYNdvfoSO6Meta?.redirect,
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingIYNdvfoSO6Meta?.name ?? "pricing___ja",
    path: pricingIYNdvfoSO6Meta?.path ?? "/ja/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    alias: pricingIYNdvfoSO6Meta?.alias || [],
    redirect: pricingIYNdvfoSO6Meta?.redirect,
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingIYNdvfoSO6Meta?.name ?? "pricing___es",
    path: pricingIYNdvfoSO6Meta?.path ?? "/es/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    alias: pricingIYNdvfoSO6Meta?.alias || [],
    redirect: pricingIYNdvfoSO6Meta?.redirect,
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91type_93WRnDy6FAqyMeta?.name ?? "purchase-credits-type___en-au",
    path: _91type_93WRnDy6FAqyMeta?.path ?? "/purchase-credits/:type()",
    meta: _91type_93WRnDy6FAqyMeta || {},
    alias: _91type_93WRnDy6FAqyMeta?.alias || [],
    redirect: _91type_93WRnDy6FAqyMeta?.redirect,
    component: () => import("/vercel/path0/pages/purchase-credits/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93WRnDy6FAqyMeta?.name ?? "purchase-credits-type___en-us",
    path: _91type_93WRnDy6FAqyMeta?.path ?? "/en-us/purchase-credits/:type()",
    meta: _91type_93WRnDy6FAqyMeta || {},
    alias: _91type_93WRnDy6FAqyMeta?.alias || [],
    redirect: _91type_93WRnDy6FAqyMeta?.redirect,
    component: () => import("/vercel/path0/pages/purchase-credits/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93WRnDy6FAqyMeta?.name ?? "purchase-credits-type___de",
    path: _91type_93WRnDy6FAqyMeta?.path ?? "/de/purchase-credits/:type()",
    meta: _91type_93WRnDy6FAqyMeta || {},
    alias: _91type_93WRnDy6FAqyMeta?.alias || [],
    redirect: _91type_93WRnDy6FAqyMeta?.redirect,
    component: () => import("/vercel/path0/pages/purchase-credits/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93WRnDy6FAqyMeta?.name ?? "purchase-credits-type___fr",
    path: _91type_93WRnDy6FAqyMeta?.path ?? "/fr/purchase-credits/:type()",
    meta: _91type_93WRnDy6FAqyMeta || {},
    alias: _91type_93WRnDy6FAqyMeta?.alias || [],
    redirect: _91type_93WRnDy6FAqyMeta?.redirect,
    component: () => import("/vercel/path0/pages/purchase-credits/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93WRnDy6FAqyMeta?.name ?? "purchase-credits-type___ja",
    path: _91type_93WRnDy6FAqyMeta?.path ?? "/ja/purchase-credits/:type()",
    meta: _91type_93WRnDy6FAqyMeta || {},
    alias: _91type_93WRnDy6FAqyMeta?.alias || [],
    redirect: _91type_93WRnDy6FAqyMeta?.redirect,
    component: () => import("/vercel/path0/pages/purchase-credits/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93WRnDy6FAqyMeta?.name ?? "purchase-credits-type___es",
    path: _91type_93WRnDy6FAqyMeta?.path ?? "/es/purchase-credits/:type()",
    meta: _91type_93WRnDy6FAqyMeta || {},
    alias: _91type_93WRnDy6FAqyMeta?.alias || [],
    redirect: _91type_93WRnDy6FAqyMeta?.redirect,
    component: () => import("/vercel/path0/pages/purchase-credits/[type].vue").then(m => m.default || m)
  },
  {
    name: recordkrEjRpuW0KMeta?.name ?? "record___en-au",
    path: recordkrEjRpuW0KMeta?.path ?? "/record",
    meta: recordkrEjRpuW0KMeta || {},
    alias: recordkrEjRpuW0KMeta?.alias || [],
    redirect: recordkrEjRpuW0KMeta?.redirect,
    component: () => import("/vercel/path0/pages/record.vue").then(m => m.default || m)
  },
  {
    name: recordkrEjRpuW0KMeta?.name ?? "record___en-us",
    path: recordkrEjRpuW0KMeta?.path ?? "/en-us/record",
    meta: recordkrEjRpuW0KMeta || {},
    alias: recordkrEjRpuW0KMeta?.alias || [],
    redirect: recordkrEjRpuW0KMeta?.redirect,
    component: () => import("/vercel/path0/pages/record.vue").then(m => m.default || m)
  },
  {
    name: recordkrEjRpuW0KMeta?.name ?? "record___de",
    path: recordkrEjRpuW0KMeta?.path ?? "/de/record",
    meta: recordkrEjRpuW0KMeta || {},
    alias: recordkrEjRpuW0KMeta?.alias || [],
    redirect: recordkrEjRpuW0KMeta?.redirect,
    component: () => import("/vercel/path0/pages/record.vue").then(m => m.default || m)
  },
  {
    name: recordkrEjRpuW0KMeta?.name ?? "record___fr",
    path: recordkrEjRpuW0KMeta?.path ?? "/fr/record",
    meta: recordkrEjRpuW0KMeta || {},
    alias: recordkrEjRpuW0KMeta?.alias || [],
    redirect: recordkrEjRpuW0KMeta?.redirect,
    component: () => import("/vercel/path0/pages/record.vue").then(m => m.default || m)
  },
  {
    name: recordkrEjRpuW0KMeta?.name ?? "record___ja",
    path: recordkrEjRpuW0KMeta?.path ?? "/ja/record",
    meta: recordkrEjRpuW0KMeta || {},
    alias: recordkrEjRpuW0KMeta?.alias || [],
    redirect: recordkrEjRpuW0KMeta?.redirect,
    component: () => import("/vercel/path0/pages/record.vue").then(m => m.default || m)
  },
  {
    name: recordkrEjRpuW0KMeta?.name ?? "record___es",
    path: recordkrEjRpuW0KMeta?.path ?? "/es/record",
    meta: recordkrEjRpuW0KMeta || {},
    alias: recordkrEjRpuW0KMeta?.alias || [],
    redirect: recordkrEjRpuW0KMeta?.redirect,
    component: () => import("/vercel/path0/pages/record.vue").then(m => m.default || m)
  },
  {
    name: reset_45password7wK2GPZgVKMeta?.name ?? "reset-password___en-au",
    path: reset_45password7wK2GPZgVKMeta?.path ?? "/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    alias: reset_45password7wK2GPZgVKMeta?.alias || [],
    redirect: reset_45password7wK2GPZgVKMeta?.redirect,
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password7wK2GPZgVKMeta?.name ?? "reset-password___en-us",
    path: reset_45password7wK2GPZgVKMeta?.path ?? "/en-us/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    alias: reset_45password7wK2GPZgVKMeta?.alias || [],
    redirect: reset_45password7wK2GPZgVKMeta?.redirect,
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password7wK2GPZgVKMeta?.name ?? "reset-password___de",
    path: reset_45password7wK2GPZgVKMeta?.path ?? "/de/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    alias: reset_45password7wK2GPZgVKMeta?.alias || [],
    redirect: reset_45password7wK2GPZgVKMeta?.redirect,
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password7wK2GPZgVKMeta?.name ?? "reset-password___fr",
    path: reset_45password7wK2GPZgVKMeta?.path ?? "/fr/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    alias: reset_45password7wK2GPZgVKMeta?.alias || [],
    redirect: reset_45password7wK2GPZgVKMeta?.redirect,
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password7wK2GPZgVKMeta?.name ?? "reset-password___ja",
    path: reset_45password7wK2GPZgVKMeta?.path ?? "/ja/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    alias: reset_45password7wK2GPZgVKMeta?.alias || [],
    redirect: reset_45password7wK2GPZgVKMeta?.redirect,
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password7wK2GPZgVKMeta?.name ?? "reset-password___es",
    path: reset_45password7wK2GPZgVKMeta?.path ?? "/es/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    alias: reset_45password7wK2GPZgVKMeta?.alias || [],
    redirect: reset_45password7wK2GPZgVKMeta?.redirect,
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: roadmap9jmg3pAyq3Meta?.name ?? "roadmap___en-au",
    path: roadmap9jmg3pAyq3Meta?.path ?? "/roadmap",
    meta: roadmap9jmg3pAyq3Meta || {},
    alias: roadmap9jmg3pAyq3Meta?.alias || [],
    redirect: roadmap9jmg3pAyq3Meta?.redirect,
    component: () => import("/vercel/path0/pages/roadmap.vue").then(m => m.default || m)
  },
  {
    name: roadmap9jmg3pAyq3Meta?.name ?? "roadmap___en-us",
    path: roadmap9jmg3pAyq3Meta?.path ?? "/en-us/roadmap",
    meta: roadmap9jmg3pAyq3Meta || {},
    alias: roadmap9jmg3pAyq3Meta?.alias || [],
    redirect: roadmap9jmg3pAyq3Meta?.redirect,
    component: () => import("/vercel/path0/pages/roadmap.vue").then(m => m.default || m)
  },
  {
    name: roadmap9jmg3pAyq3Meta?.name ?? "roadmap___de",
    path: roadmap9jmg3pAyq3Meta?.path ?? "/de/roadmap",
    meta: roadmap9jmg3pAyq3Meta || {},
    alias: roadmap9jmg3pAyq3Meta?.alias || [],
    redirect: roadmap9jmg3pAyq3Meta?.redirect,
    component: () => import("/vercel/path0/pages/roadmap.vue").then(m => m.default || m)
  },
  {
    name: roadmap9jmg3pAyq3Meta?.name ?? "roadmap___fr",
    path: roadmap9jmg3pAyq3Meta?.path ?? "/fr/roadmap",
    meta: roadmap9jmg3pAyq3Meta || {},
    alias: roadmap9jmg3pAyq3Meta?.alias || [],
    redirect: roadmap9jmg3pAyq3Meta?.redirect,
    component: () => import("/vercel/path0/pages/roadmap.vue").then(m => m.default || m)
  },
  {
    name: roadmap9jmg3pAyq3Meta?.name ?? "roadmap___ja",
    path: roadmap9jmg3pAyq3Meta?.path ?? "/ja/roadmap",
    meta: roadmap9jmg3pAyq3Meta || {},
    alias: roadmap9jmg3pAyq3Meta?.alias || [],
    redirect: roadmap9jmg3pAyq3Meta?.redirect,
    component: () => import("/vercel/path0/pages/roadmap.vue").then(m => m.default || m)
  },
  {
    name: roadmap9jmg3pAyq3Meta?.name ?? "roadmap___es",
    path: roadmap9jmg3pAyq3Meta?.path ?? "/es/roadmap",
    meta: roadmap9jmg3pAyq3Meta || {},
    alias: roadmap9jmg3pAyq3Meta?.alias || [],
    redirect: roadmap9jmg3pAyq3Meta?.redirect,
    component: () => import("/vercel/path0/pages/roadmap.vue").then(m => m.default || m)
  },
  {
    name: signin0XCmIKo45IMeta?.name ?? "signin___en-au",
    path: signin0XCmIKo45IMeta?.path ?? "/signin",
    meta: signin0XCmIKo45IMeta || {},
    alias: signin0XCmIKo45IMeta?.alias || [],
    redirect: signin0XCmIKo45IMeta?.redirect,
    component: () => import("/vercel/path0/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signin0XCmIKo45IMeta?.name ?? "signin___en-us",
    path: signin0XCmIKo45IMeta?.path ?? "/en-us/signin",
    meta: signin0XCmIKo45IMeta || {},
    alias: signin0XCmIKo45IMeta?.alias || [],
    redirect: signin0XCmIKo45IMeta?.redirect,
    component: () => import("/vercel/path0/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signin0XCmIKo45IMeta?.name ?? "signin___de",
    path: signin0XCmIKo45IMeta?.path ?? "/de/signin",
    meta: signin0XCmIKo45IMeta || {},
    alias: signin0XCmIKo45IMeta?.alias || [],
    redirect: signin0XCmIKo45IMeta?.redirect,
    component: () => import("/vercel/path0/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signin0XCmIKo45IMeta?.name ?? "signin___fr",
    path: signin0XCmIKo45IMeta?.path ?? "/fr/signin",
    meta: signin0XCmIKo45IMeta || {},
    alias: signin0XCmIKo45IMeta?.alias || [],
    redirect: signin0XCmIKo45IMeta?.redirect,
    component: () => import("/vercel/path0/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signin0XCmIKo45IMeta?.name ?? "signin___ja",
    path: signin0XCmIKo45IMeta?.path ?? "/ja/signin",
    meta: signin0XCmIKo45IMeta || {},
    alias: signin0XCmIKo45IMeta?.alias || [],
    redirect: signin0XCmIKo45IMeta?.redirect,
    component: () => import("/vercel/path0/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signin0XCmIKo45IMeta?.name ?? "signin___es",
    path: signin0XCmIKo45IMeta?.path ?? "/es/signin",
    meta: signin0XCmIKo45IMeta || {},
    alias: signin0XCmIKo45IMeta?.alias || [],
    redirect: signin0XCmIKo45IMeta?.redirect,
    component: () => import("/vercel/path0/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup___en-au",
    path: signupY93ILdpGpbMeta?.path ?? "/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup___en-us",
    path: signupY93ILdpGpbMeta?.path ?? "/en-us/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup___de",
    path: signupY93ILdpGpbMeta?.path ?? "/de/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup___fr",
    path: signupY93ILdpGpbMeta?.path ?? "/fr/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup___ja",
    path: signupY93ILdpGpbMeta?.path ?? "/ja/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup___es",
    path: signupY93ILdpGpbMeta?.path ?? "/es/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: supportJTqUQCOdxvMeta?.name ?? "support___en-au",
    path: supportJTqUQCOdxvMeta?.path ?? "/support",
    meta: supportJTqUQCOdxvMeta || {},
    alias: supportJTqUQCOdxvMeta?.alias || [],
    redirect: supportJTqUQCOdxvMeta?.redirect,
    component: () => import("/vercel/path0/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportJTqUQCOdxvMeta?.name ?? "support___en-us",
    path: supportJTqUQCOdxvMeta?.path ?? "/en-us/support",
    meta: supportJTqUQCOdxvMeta || {},
    alias: supportJTqUQCOdxvMeta?.alias || [],
    redirect: supportJTqUQCOdxvMeta?.redirect,
    component: () => import("/vercel/path0/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportJTqUQCOdxvMeta?.name ?? "support___de",
    path: supportJTqUQCOdxvMeta?.path ?? "/de/support",
    meta: supportJTqUQCOdxvMeta || {},
    alias: supportJTqUQCOdxvMeta?.alias || [],
    redirect: supportJTqUQCOdxvMeta?.redirect,
    component: () => import("/vercel/path0/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportJTqUQCOdxvMeta?.name ?? "support___fr",
    path: supportJTqUQCOdxvMeta?.path ?? "/fr/support",
    meta: supportJTqUQCOdxvMeta || {},
    alias: supportJTqUQCOdxvMeta?.alias || [],
    redirect: supportJTqUQCOdxvMeta?.redirect,
    component: () => import("/vercel/path0/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportJTqUQCOdxvMeta?.name ?? "support___ja",
    path: supportJTqUQCOdxvMeta?.path ?? "/ja/support",
    meta: supportJTqUQCOdxvMeta || {},
    alias: supportJTqUQCOdxvMeta?.alias || [],
    redirect: supportJTqUQCOdxvMeta?.redirect,
    component: () => import("/vercel/path0/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportJTqUQCOdxvMeta?.name ?? "support___es",
    path: supportJTqUQCOdxvMeta?.path ?? "/es/support",
    meta: supportJTqUQCOdxvMeta || {},
    alias: supportJTqUQCOdxvMeta?.alias || [],
    redirect: supportJTqUQCOdxvMeta?.redirect,
    component: () => import("/vercel/path0/pages/support.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BjLa7yd6yyMeta?.name ?? "template-slug___en-au",
    path: _91slug_93BjLa7yd6yyMeta?.path ?? "/template/:slug()",
    meta: _91slug_93BjLa7yd6yyMeta || {},
    alias: _91slug_93BjLa7yd6yyMeta?.alias || [],
    redirect: _91slug_93BjLa7yd6yyMeta?.redirect,
    component: () => import("/vercel/path0/pages/template/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BjLa7yd6yyMeta?.name ?? "template-slug___en-us",
    path: _91slug_93BjLa7yd6yyMeta?.path ?? "/en-us/template/:slug()",
    meta: _91slug_93BjLa7yd6yyMeta || {},
    alias: _91slug_93BjLa7yd6yyMeta?.alias || [],
    redirect: _91slug_93BjLa7yd6yyMeta?.redirect,
    component: () => import("/vercel/path0/pages/template/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BjLa7yd6yyMeta?.name ?? "template-slug___de",
    path: _91slug_93BjLa7yd6yyMeta?.path ?? "/de/template/:slug()",
    meta: _91slug_93BjLa7yd6yyMeta || {},
    alias: _91slug_93BjLa7yd6yyMeta?.alias || [],
    redirect: _91slug_93BjLa7yd6yyMeta?.redirect,
    component: () => import("/vercel/path0/pages/template/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BjLa7yd6yyMeta?.name ?? "template-slug___fr",
    path: _91slug_93BjLa7yd6yyMeta?.path ?? "/fr/template/:slug()",
    meta: _91slug_93BjLa7yd6yyMeta || {},
    alias: _91slug_93BjLa7yd6yyMeta?.alias || [],
    redirect: _91slug_93BjLa7yd6yyMeta?.redirect,
    component: () => import("/vercel/path0/pages/template/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BjLa7yd6yyMeta?.name ?? "template-slug___ja",
    path: _91slug_93BjLa7yd6yyMeta?.path ?? "/ja/template/:slug()",
    meta: _91slug_93BjLa7yd6yyMeta || {},
    alias: _91slug_93BjLa7yd6yyMeta?.alias || [],
    redirect: _91slug_93BjLa7yd6yyMeta?.redirect,
    component: () => import("/vercel/path0/pages/template/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BjLa7yd6yyMeta?.name ?? "template-slug___es",
    path: _91slug_93BjLa7yd6yyMeta?.path ?? "/es/template/:slug()",
    meta: _91slug_93BjLa7yd6yyMeta || {},
    alias: _91slug_93BjLa7yd6yyMeta?.alias || [],
    redirect: _91slug_93BjLa7yd6yyMeta?.redirect,
    component: () => import("/vercel/path0/pages/template/[slug].vue").then(m => m.default || m)
  },
  {
    name: text_45to_45speechBJsXTYRsJeMeta?.name ?? "text-to-speech___en-au",
    path: text_45to_45speechBJsXTYRsJeMeta?.path ?? "/text-to-speech",
    meta: text_45to_45speechBJsXTYRsJeMeta || {},
    alias: text_45to_45speechBJsXTYRsJeMeta?.alias || [],
    redirect: text_45to_45speechBJsXTYRsJeMeta?.redirect,
    component: () => import("/vercel/path0/pages/text-to-speech.vue").then(m => m.default || m)
  },
  {
    name: text_45to_45speechBJsXTYRsJeMeta?.name ?? "text-to-speech___en-us",
    path: text_45to_45speechBJsXTYRsJeMeta?.path ?? "/en-us/text-to-speech",
    meta: text_45to_45speechBJsXTYRsJeMeta || {},
    alias: text_45to_45speechBJsXTYRsJeMeta?.alias || [],
    redirect: text_45to_45speechBJsXTYRsJeMeta?.redirect,
    component: () => import("/vercel/path0/pages/text-to-speech.vue").then(m => m.default || m)
  },
  {
    name: text_45to_45speechBJsXTYRsJeMeta?.name ?? "text-to-speech___de",
    path: text_45to_45speechBJsXTYRsJeMeta?.path ?? "/de/text-to-speech",
    meta: text_45to_45speechBJsXTYRsJeMeta || {},
    alias: text_45to_45speechBJsXTYRsJeMeta?.alias || [],
    redirect: text_45to_45speechBJsXTYRsJeMeta?.redirect,
    component: () => import("/vercel/path0/pages/text-to-speech.vue").then(m => m.default || m)
  },
  {
    name: text_45to_45speechBJsXTYRsJeMeta?.name ?? "text-to-speech___fr",
    path: text_45to_45speechBJsXTYRsJeMeta?.path ?? "/fr/text-to-speech",
    meta: text_45to_45speechBJsXTYRsJeMeta || {},
    alias: text_45to_45speechBJsXTYRsJeMeta?.alias || [],
    redirect: text_45to_45speechBJsXTYRsJeMeta?.redirect,
    component: () => import("/vercel/path0/pages/text-to-speech.vue").then(m => m.default || m)
  },
  {
    name: text_45to_45speechBJsXTYRsJeMeta?.name ?? "text-to-speech___ja",
    path: text_45to_45speechBJsXTYRsJeMeta?.path ?? "/ja/text-to-speech",
    meta: text_45to_45speechBJsXTYRsJeMeta || {},
    alias: text_45to_45speechBJsXTYRsJeMeta?.alias || [],
    redirect: text_45to_45speechBJsXTYRsJeMeta?.redirect,
    component: () => import("/vercel/path0/pages/text-to-speech.vue").then(m => m.default || m)
  },
  {
    name: text_45to_45speechBJsXTYRsJeMeta?.name ?? "text-to-speech___es",
    path: text_45to_45speechBJsXTYRsJeMeta?.path ?? "/es/text-to-speech",
    meta: text_45to_45speechBJsXTYRsJeMeta || {},
    alias: text_45to_45speechBJsXTYRsJeMeta?.alias || [],
    redirect: text_45to_45speechBJsXTYRsJeMeta?.redirect,
    component: () => import("/vercel/path0/pages/text-to-speech.vue").then(m => m.default || m)
  },
  {
    name: _91key_93ND7sIEocuOMeta?.name ?? "tts-scripts-key___en-au",
    path: _91key_93ND7sIEocuOMeta?.path ?? "/tts-scripts/:key()",
    meta: _91key_93ND7sIEocuOMeta || {},
    alias: _91key_93ND7sIEocuOMeta?.alias || [],
    redirect: _91key_93ND7sIEocuOMeta?.redirect,
    component: () => import("/vercel/path0/pages/tts-scripts/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93ND7sIEocuOMeta?.name ?? "tts-scripts-key___en-us",
    path: _91key_93ND7sIEocuOMeta?.path ?? "/en-us/tts-scripts/:key()",
    meta: _91key_93ND7sIEocuOMeta || {},
    alias: _91key_93ND7sIEocuOMeta?.alias || [],
    redirect: _91key_93ND7sIEocuOMeta?.redirect,
    component: () => import("/vercel/path0/pages/tts-scripts/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93ND7sIEocuOMeta?.name ?? "tts-scripts-key___de",
    path: _91key_93ND7sIEocuOMeta?.path ?? "/de/tts-scripts/:key()",
    meta: _91key_93ND7sIEocuOMeta || {},
    alias: _91key_93ND7sIEocuOMeta?.alias || [],
    redirect: _91key_93ND7sIEocuOMeta?.redirect,
    component: () => import("/vercel/path0/pages/tts-scripts/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93ND7sIEocuOMeta?.name ?? "tts-scripts-key___fr",
    path: _91key_93ND7sIEocuOMeta?.path ?? "/fr/tts-scripts/:key()",
    meta: _91key_93ND7sIEocuOMeta || {},
    alias: _91key_93ND7sIEocuOMeta?.alias || [],
    redirect: _91key_93ND7sIEocuOMeta?.redirect,
    component: () => import("/vercel/path0/pages/tts-scripts/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93ND7sIEocuOMeta?.name ?? "tts-scripts-key___ja",
    path: _91key_93ND7sIEocuOMeta?.path ?? "/ja/tts-scripts/:key()",
    meta: _91key_93ND7sIEocuOMeta || {},
    alias: _91key_93ND7sIEocuOMeta?.alias || [],
    redirect: _91key_93ND7sIEocuOMeta?.redirect,
    component: () => import("/vercel/path0/pages/tts-scripts/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93ND7sIEocuOMeta?.name ?? "tts-scripts-key___es",
    path: _91key_93ND7sIEocuOMeta?.path ?? "/es/tts-scripts/:key()",
    meta: _91key_93ND7sIEocuOMeta || {},
    alias: _91key_93ND7sIEocuOMeta?.alias || [],
    redirect: _91key_93ND7sIEocuOMeta?.redirect,
    component: () => import("/vercel/path0/pages/tts-scripts/[key].vue").then(m => m.default || m)
  },
  {
    name: mineEbZPHbliNPMeta?.name ?? "tts-scripts-mine___en-au",
    path: mineEbZPHbliNPMeta?.path ?? "/tts-scripts/mine",
    meta: mineEbZPHbliNPMeta || {},
    alias: mineEbZPHbliNPMeta?.alias || [],
    redirect: mineEbZPHbliNPMeta?.redirect,
    component: () => import("/vercel/path0/pages/tts-scripts/mine.vue").then(m => m.default || m)
  },
  {
    name: mineEbZPHbliNPMeta?.name ?? "tts-scripts-mine___en-us",
    path: mineEbZPHbliNPMeta?.path ?? "/en-us/tts-scripts/mine",
    meta: mineEbZPHbliNPMeta || {},
    alias: mineEbZPHbliNPMeta?.alias || [],
    redirect: mineEbZPHbliNPMeta?.redirect,
    component: () => import("/vercel/path0/pages/tts-scripts/mine.vue").then(m => m.default || m)
  },
  {
    name: mineEbZPHbliNPMeta?.name ?? "tts-scripts-mine___de",
    path: mineEbZPHbliNPMeta?.path ?? "/de/tts-scripts/mine",
    meta: mineEbZPHbliNPMeta || {},
    alias: mineEbZPHbliNPMeta?.alias || [],
    redirect: mineEbZPHbliNPMeta?.redirect,
    component: () => import("/vercel/path0/pages/tts-scripts/mine.vue").then(m => m.default || m)
  },
  {
    name: mineEbZPHbliNPMeta?.name ?? "tts-scripts-mine___fr",
    path: mineEbZPHbliNPMeta?.path ?? "/fr/tts-scripts/mine",
    meta: mineEbZPHbliNPMeta || {},
    alias: mineEbZPHbliNPMeta?.alias || [],
    redirect: mineEbZPHbliNPMeta?.redirect,
    component: () => import("/vercel/path0/pages/tts-scripts/mine.vue").then(m => m.default || m)
  },
  {
    name: mineEbZPHbliNPMeta?.name ?? "tts-scripts-mine___ja",
    path: mineEbZPHbliNPMeta?.path ?? "/ja/tts-scripts/mine",
    meta: mineEbZPHbliNPMeta || {},
    alias: mineEbZPHbliNPMeta?.alias || [],
    redirect: mineEbZPHbliNPMeta?.redirect,
    component: () => import("/vercel/path0/pages/tts-scripts/mine.vue").then(m => m.default || m)
  },
  {
    name: mineEbZPHbliNPMeta?.name ?? "tts-scripts-mine___es",
    path: mineEbZPHbliNPMeta?.path ?? "/es/tts-scripts/mine",
    meta: mineEbZPHbliNPMeta || {},
    alias: mineEbZPHbliNPMeta?.alias || [],
    redirect: mineEbZPHbliNPMeta?.redirect,
    component: () => import("/vercel/path0/pages/tts-scripts/mine.vue").then(m => m.default || m)
  },
  {
    name: upload_45audio122HNptPedMeta?.name ?? "upload-audio___en-au",
    path: upload_45audio122HNptPedMeta?.path ?? "/upload-audio",
    meta: upload_45audio122HNptPedMeta || {},
    alias: upload_45audio122HNptPedMeta?.alias || [],
    redirect: upload_45audio122HNptPedMeta?.redirect,
    component: () => import("/vercel/path0/pages/upload-audio.vue").then(m => m.default || m)
  },
  {
    name: upload_45audio122HNptPedMeta?.name ?? "upload-audio___en-us",
    path: upload_45audio122HNptPedMeta?.path ?? "/en-us/upload-audio",
    meta: upload_45audio122HNptPedMeta || {},
    alias: upload_45audio122HNptPedMeta?.alias || [],
    redirect: upload_45audio122HNptPedMeta?.redirect,
    component: () => import("/vercel/path0/pages/upload-audio.vue").then(m => m.default || m)
  },
  {
    name: upload_45audio122HNptPedMeta?.name ?? "upload-audio___de",
    path: upload_45audio122HNptPedMeta?.path ?? "/de/upload-audio",
    meta: upload_45audio122HNptPedMeta || {},
    alias: upload_45audio122HNptPedMeta?.alias || [],
    redirect: upload_45audio122HNptPedMeta?.redirect,
    component: () => import("/vercel/path0/pages/upload-audio.vue").then(m => m.default || m)
  },
  {
    name: upload_45audio122HNptPedMeta?.name ?? "upload-audio___fr",
    path: upload_45audio122HNptPedMeta?.path ?? "/fr/upload-audio",
    meta: upload_45audio122HNptPedMeta || {},
    alias: upload_45audio122HNptPedMeta?.alias || [],
    redirect: upload_45audio122HNptPedMeta?.redirect,
    component: () => import("/vercel/path0/pages/upload-audio.vue").then(m => m.default || m)
  },
  {
    name: upload_45audio122HNptPedMeta?.name ?? "upload-audio___ja",
    path: upload_45audio122HNptPedMeta?.path ?? "/ja/upload-audio",
    meta: upload_45audio122HNptPedMeta || {},
    alias: upload_45audio122HNptPedMeta?.alias || [],
    redirect: upload_45audio122HNptPedMeta?.redirect,
    component: () => import("/vercel/path0/pages/upload-audio.vue").then(m => m.default || m)
  },
  {
    name: upload_45audio122HNptPedMeta?.name ?? "upload-audio___es",
    path: upload_45audio122HNptPedMeta?.path ?? "/es/upload-audio",
    meta: upload_45audio122HNptPedMeta || {},
    alias: upload_45audio122HNptPedMeta?.alias || [],
    redirect: upload_45audio122HNptPedMeta?.redirect,
    component: () => import("/vercel/path0/pages/upload-audio.vue").then(m => m.default || m)
  },
  {
    name: _91key_93HlPIIbmFTmMeta?.name ?? "view-key___en-au",
    path: _91key_93HlPIIbmFTmMeta?.path ?? "/view/:key()",
    meta: _91key_93HlPIIbmFTmMeta || {},
    alias: _91key_93HlPIIbmFTmMeta?.alias || [],
    redirect: _91key_93HlPIIbmFTmMeta?.redirect,
    component: () => import("/vercel/path0/pages/view/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93HlPIIbmFTmMeta?.name ?? "view-key___en-us",
    path: _91key_93HlPIIbmFTmMeta?.path ?? "/en-us/view/:key()",
    meta: _91key_93HlPIIbmFTmMeta || {},
    alias: _91key_93HlPIIbmFTmMeta?.alias || [],
    redirect: _91key_93HlPIIbmFTmMeta?.redirect,
    component: () => import("/vercel/path0/pages/view/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93HlPIIbmFTmMeta?.name ?? "view-key___de",
    path: _91key_93HlPIIbmFTmMeta?.path ?? "/de/view/:key()",
    meta: _91key_93HlPIIbmFTmMeta || {},
    alias: _91key_93HlPIIbmFTmMeta?.alias || [],
    redirect: _91key_93HlPIIbmFTmMeta?.redirect,
    component: () => import("/vercel/path0/pages/view/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93HlPIIbmFTmMeta?.name ?? "view-key___fr",
    path: _91key_93HlPIIbmFTmMeta?.path ?? "/fr/view/:key()",
    meta: _91key_93HlPIIbmFTmMeta || {},
    alias: _91key_93HlPIIbmFTmMeta?.alias || [],
    redirect: _91key_93HlPIIbmFTmMeta?.redirect,
    component: () => import("/vercel/path0/pages/view/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93HlPIIbmFTmMeta?.name ?? "view-key___ja",
    path: _91key_93HlPIIbmFTmMeta?.path ?? "/ja/view/:key()",
    meta: _91key_93HlPIIbmFTmMeta || {},
    alias: _91key_93HlPIIbmFTmMeta?.alias || [],
    redirect: _91key_93HlPIIbmFTmMeta?.redirect,
    component: () => import("/vercel/path0/pages/view/[key].vue").then(m => m.default || m)
  },
  {
    name: _91key_93HlPIIbmFTmMeta?.name ?? "view-key___es",
    path: _91key_93HlPIIbmFTmMeta?.path ?? "/es/view/:key()",
    meta: _91key_93HlPIIbmFTmMeta || {},
    alias: _91key_93HlPIIbmFTmMeta?.alias || [],
    redirect: _91key_93HlPIIbmFTmMeta?.redirect,
    component: () => import("/vercel/path0/pages/view/[key].vue").then(m => m.default || m)
  }
]